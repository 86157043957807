const resourcePath='@/store/state.js';import config from '@/config.js'
export default {
    nodes: [
        {
            "miks_created_at": 1553159465,
            "miks_updated_at": 1553159465,
            "miks_ip": "https://iyuanben.com:30613",
            "miks_name": "点到",
            "miks_avatar": `https://${config.miks_oss_bucket_url}/repository/images/211553515320_.pic.jpg`,
            "miks_domain": "https://dd.diandao.pro",
            "miks_abstract": "点到 Node",

            "abstract": "点到 Node",
            "api": config.base_url,
            "avatar": `https://${config.miks_oss_bucket_url}/repository/images/211553515320_.pic.jpg`,
            "created_at": 1553159465,
            "id": "0x77297b9a53d4016259ed3f7c09f8c38517c26cc7",
            "name": "点到",
            "node_address": "0x77297b9a53d4016259ed3f7c09f8c38517c26cc7",
            "updated_at": 1553159465,
            "web": `https://${config.appname}.com`
        }
    ],

    // 用户基本信息
    user: {
        email: '',
        head_img: {},
        head_img_id: '',
        head_img_url: '',
        manager: null,
        name: '',
        out_uid: '',
        type: 0
    },

    // 我加入的圈子列表（分页）
    myGroups: [],

    // isPhone: window.innerWidth <= 1160 ? true : false,
    isPhone: window.innerWidth <= 600 ? true : false,
    page_scroll_fixed: false,//页面滚动超过600px 为true,
    newsTags: [],//火眼标签列表
    light_header: false,

    locale: 'zh',

    // 当前文章操作菜单
    currentMenu: {
        show: false,
        article_id: '',
        type: 'original-menu'
    },

    // 小组基本信息（分页才需要 loading，不分页时，只需要在一处初始化请求）
    currentGroup: {
        // loading: false,
        latest_id: '',
        data: {
            name: '',
            group_id: '',
            // head_img: {
                // storage_url: ''  //数据扁平化处理
            // },
            // article_count: 0,    //新增、删除文章更新Model
        }
    },

    // 小组成员列表（分页：page也需要存起来，共用）
    groupMember: {
        // loading: false,    //用页面共用的loading
        // latest_id: '',    //存储在currentGroup
        pageParams: {
            page: 0,
            page_size: 999
        },
        list: []
    },

    // 我的小组
    groups: {
        pageParams: {
            page: 0,
            page_size: 20,
            source: 1
        },
        list: []
    },


    // 小组文件、一切写
    groupDashboard: {
        doc_list: [],
        file_list: [],
        article_picks: []
    },

    // 新手引导提示
    guide: {
        stepOne: false,
        stepTwo: false
    },

    article: {
        article_id: '',

    },
    appVersionInfo:{
      version:'0.0.0',
      buildTime:0
    },
    outdated:false
}
