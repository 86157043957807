const resourcePath='@/store/modules/matrixChat.js';const state = {
   chatGrpIds:localStorage.getItem('MIKS_MATRIX_CHATGRPIDS') ? JSON.parse(localStorage.getItem('MIKS_MATRIX_CHATGRPIDS')):[],
   chatingGrpId:null
};

const getters = {
   
};

const actions = {
};


const mutations = {
   joinGrpChat (state,grp_id) {
      if(state.chatGrpIds.findIndex((itm)=>itm == grp_id) < 0) {
         state.chatGrpIds.push(grp_id)
         localStorage.setItem('MIKS_MATRIX_CHATGRPIDS',JSON.stringify(state.chatGrpIds))
      }
   },
   leaveGrpChat (state,grp_id) {
      state.chatGrpIds = state.chatGrpIds.filter((itm)=>itm != grp_id)
      if(state.chatingGrpId == grp_id) {
         state.chatingGrpId = null
      }
      localStorage.setItem('MIKS_MATRIX_CHATGRPIDS',JSON.stringify(state.chatGrpIds))
   },
   selectChatingGrpId(state,grp_id) {
      if(state.chatGrpIds.findIndex((itm)=>itm == grp_id) >= 0 || !grp_id) {
         state.chatingGrpId = grp_id
      }
   }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
