const resourcePath='@/main.js';import "regenerator-runtime/runtime";
import Vue from 'vue'
import VueMeta from 'vue-meta'
import router, {
   paths
} from './router'
import store from './store/index'
import './common/common'
import './axios.config'
import config from '@/config.js'
Vue.prototype.$config = config
Vue.use(VueMeta, {
   refreshOnceOnNavigation: true
})

import i18n from './common/function/i18n.js'
Vue.prototype.$t = (key, value) => i18n.t(key, value);


import * as Sentry from "@sentry/browser";
import {
   Vue as VueIntegration
} from "@sentry/integrations";
import {
   Integrations
} from "@sentry/tracing";

//temporarily disable sentry 
if (process.env.NODE_ENV === "production" && location.origin.replace(/www\./i, "") === `https://${config.appname}.com`) {
   Sentry.addGlobalEventProcessor((event, hint) => {
      if (localStorage.getItem('session_key') != null) {
         let session_key = localStorage.getItem('session_key')
         if (event.user)
            event.user.session_key = session_key
         else
            event.user = {
               session_key: session_key
            }
         return event
      }
      return event
   })
   Sentry.init({
      dsn: config.sentry_dsn,
      integrations: [
         new VueIntegration({
            Vue,
            tracing: true,
            logErrors: true,
            tracingOptions: {
               trackComponents: true,
            },
         }),
         new Integrations.BrowserTracing()
      ],
      ignoreErrors: [
         "Non-Error exception captured",
         "Non-Error promise rejection captured"
      ],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,

      environment: process.env.NODE_ENV,
   });
}
import 'viewerjs/dist/viewer.css'
import Dialog from 'element-ui/lib/dialog'
import Tooltip from 'element-ui/lib/tooltip'
import Upload from 'element-ui/lib/upload'
import Button from 'element-ui/lib/button'
import Input from 'element-ui/lib/input'
import Table from 'element-ui/lib/table'
import TableColumn from 'element-ui/lib/table-column'
import Select from 'element-ui/lib/select'
import Option from 'element-ui/lib/option'
import Tabs from 'element-ui/lib/tabs'
import TabPane from 'element-ui/lib/tab-pane'
import Card from 'element-ui/lib/card'
import Drawer from 'element-ui/lib/drawer'
import CheckBox from 'element-ui/lib/checkbox'
import Message from 'element-ui/lib/message'
import MessageBox from 'element-ui/lib/message-box'
import Loading from 'element-ui/lib/loading'
import Progress from 'element-ui/lib/progress'
import DatePicker from 'element-ui/lib/date-picker'
import text2html from '@/filters/text2html.js'
import Popover from 'element-ui/lib/popover';
import ColorPicker from 'element-ui/lib/color-picker';
import VueViewer from 'v-viewer'
import RepostDialog from '@/views/dashboard/dialogs/repost-dialog.vue'
import FileDiscloseDialog from '@/views/dashboard/dialogs/file-disclose-dialog.vue'
import SmsAgreementDialog from '@/views/dashboard/dialogs/sms-agreement-dialog.vue'
import ReleaseTip from '@/components/appbanner/releaseTip.vue'
import CreateGroupDialog from '@/views/dashboard/dialogs/create-group-dialog.vue'
import ElSwitch from 'element-ui/lib/switch'
import ElCarousel from 'element-ui/lib/carousel'
import ElCarouselItem from 'element-ui/lib/carousel-item'
import {
   VuePlugin
} from '@/common/vuera';
Vue.use(VuePlugin)
Vue.use(Dialog)
Vue.use(Tooltip)
Vue.use(Upload)
Vue.use(Button)
Vue.use(Input)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Card)
Vue.use(Drawer)
Vue.use(CheckBox)
Vue.use(DatePicker)
Vue.use(Progress)
Vue.use(Popover)
Vue.use(ColorPicker)
Vue.use(VueViewer)
Vue.use(ElSwitch)
Vue.use(ElCarousel)
Vue.use(ElCarouselItem)
Vue.prototype.$message = Message;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;

const simpleText2html = function (text) {
   let res = text2html(text, null, null, null)
   return res;
}
import xssfilter from '@/common/function/xssfilter'
Vue.prototype.$xssfilter = xssfilter
Vue.filter('text2html', simpleText2html)
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
import('@/common/pluginLoaders.js').then((result)=>{
   let pluginLoaders = result.pluginLoaders;
   Object.keys(pluginLoaders).forEach((key) => {
      let loader = pluginLoaders[key]
      console.log('loader',loader)
      if (loader.registRouter) {
         loader.registRouter(paths)
      }
      if (loader.registCss) {
         loader.registCss()
      }
   })
   router.addRoutes(paths)
   return import('./App').then((App)=>{
      new Vue({
         el: '#app',
         router,
         store,
         i18n,
         render: h => h(App.default || App),
         mounted() {
            document.dispatchEvent(new Event('render-event'))
         },
         created() {
            this.$store.commit('modal_dialogs/registDialogs', {
               'repost-dialog': RepostDialog,
               'file-disclose-dialog': FileDiscloseDialog,
               'sms-agreement-dialog': SmsAgreementDialog,
               'create-grp-dialog': CreateGroupDialog
            })
            this.$store.commit('appbanner/registAppBanner', {
               'release-tip': ReleaseTip
            })
            Object.keys(pluginLoaders).forEach((key) => {
               let loader = pluginLoaders[key]
               if (loader.onInstall) {
                  loader.onInstall(this)
               }
            })
         }
      });
   })
})