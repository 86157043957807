const resourcePath='@/directives/default-link-bg.js';let default_img = require('../common/images/hasso_link.png');

async function linkbg(el, binding) {
    let reg = /http(s)?:\/\/[^\n|^\s]+/im;
    let bindV = binding.value,
        value = bindV.src,
        defaultV = bindV.default ? bindV.default : default_img,
        onerror = bindV.onerror || function(){}
        
    // console.log('update', value)
    if (value && reg.test(value)) {
        let imgsrc = await new Promise((resolve, reject) => {
            let img = new Image();
            img.onload = function () {
                el.classList.remove('error-pic')
                resolve(value)
            }
            img.onerror = function () {
                el.classList.add('error-pic')
                onerror(el)
                resolve(defaultV);
            }
            img.src = value
        });
        el.src = imgsrc;
    } else {
        el.src = defaultV;
        onerror(el)
        el.classList.add('error-pic')
    }
}
export default {
    inserted: linkbg,
    update(el, binding) {
        if (binding.arg === 'update') {
            linkbg(el, binding)
        }
    },
};
