const resourcePath='@/store/modules/notification.js';import API_notify from '@/api/notify'
import axios from 'axios'
const axiosInstance = axios.create({
   withCredentials:true
})

axiosInstance.interceptors.request.use(
   (config) => {

       // Add authentication token
       let sessionKey = localStorage.getItem('session_key');
       config.headers["SessionKey"] = sessionKey;

       return config;
   },
   (error) => {

   }
);
const notify_type = [0,1,2,3,4,5,6,7,8,9,11,12,13,14,15,18,20,21,22,24,25]
const state = {
   unread_count:0
};

const getters = {

};
const actions = {
   async update_unread_count(context) {
      let params = {
         count: 1,
         type_list: notify_type.join()
      }
      let res = await axiosInstance.get(API_notify.common_url,{params})
      context.commit('set_unread_count',res.data.data)
    }
};

const mutations = {
   set_unread_count (state, count) {
        state.unread_count = count;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
