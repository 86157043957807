const resourcePath='@/store/index.js';import vue from 'vue'
import vuex from 'vuex'
import state from './state'
import mutations from './mutations'
import actions from './actions'

import users from './modules/users'
import draft from './modules/draft'
import collaborative from './modules/collaborative'
import blankboard from '@/store/modules/blankboard'
import modal_dialogs from '@/store/modules/modal_dialogs'
import appbanner from '@/store/modules/appbanner'
import notification from '@/store/modules/notification'
import dynamiccmp from '@/store/modules/dynamiccmp'
import matrixChat from '@/store/modules/matrixChat'
import mention from '@/store/modules/mention'
import topic from '@/store/modules/topic'
vue.use(vuex);

// const store = () => {
//     return new Vuex.Store({
//         strict: process.env.NODE_ENV !== 'production',
//         state,
//         mutations,
//         actions,
//         modules: {
//             users,
//             draft,
//             collaborative
//         }
//     })
// };
// export default store

export default new vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    state,
    mutations,
    actions,
    modules: {
        users,
        draft,
        collaborative,
        blankboard,
        modal_dialogs,
        notification,
        dynamiccmp,
        appbanner,
        matrixChat,
        mention,
        topic
    }
})