const resourcePath='@/api/news.js';import config from "@/config";
const common_url = config.hotnews_server_url;

export default {
  common_url,
  hot_articles: common_url + "/auth/hot_articles", //list 接口
  yb_articles: common_url + "/yb/articles", //list 接口
  article: common_url + "/auth/article", //list 接口
  login: common_url + "/login",
  yb_tags: common_url + "/yb/tags",
  tags: common_url + "/auth/tags",
  refresh_token: common_url + "/refresh_token",
  statistic_stat: common_url + "/statistic/source/stat",
  statistic_detail: common_url + "/statistic/source/stat/detail",
  statistic_articles: common_url + "/statistic/hot/articles",
  comment: common_url + "/statistic/article/clf/comment",
  newCount: common_url + "/auth/newArticleCount",
  search: common_url + "/auth/search"
};
