const resourcePath='@/views/dashboard/dialogs/file-disclose-dialog.vue';//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import iconSvg from "@/components/icon-svg.vue";
import DisclosureAPI from '@/api/v3/disclose.js'
import Form from 'element-ui/lib/form'
import FormItem from 'element-ui/lib/form-item'
import vue from 'vue'
import 'element-ui/packages/theme-chalk/src/form.scss';
import 'element-ui/packages/theme-chalk/src/form-item.scss';
vue.use(Form)
vue.use(FormItem)
export default {
   props: {
      article: {
         type: Object,
         required: true,
      },
   },
   data(){
      const validatePhoneNumber = (rule, value, callback) => {
      if (!/^\d{11}$/.test(value)) {
        callback(new Error("请输入正确的手机号码"));
         } else {
         callback();
         }
      };
      return {
         step:0,
         linkId:'',
         fields:{
            receiver:'',
            receiverPhone:''
         },
         formRule:{
            receiver:[{required:true,trigger:'all',message:'请输入姓名'}],
            receiverPhone:[{required:true,trigger:'all',validator:validatePhoneNumber}]
         }
      }
   },
   components: {
      iconSvg,
   },
   computed: {
      discloseLink(){
         return `${window.location.protocol}//${window.location.host}/file-disclose/${this.linkId}/sms-verify`
      },
      file() {
         return this.article.files[0];
      },
      file_type() {
         let type = "file";
         let matchs = /\.(\w+)$/i.exec(this.file.title);
         if (matchs && matchs.length) {
            type = matchs[1];
            if (["pdf"].indexOf(type) !== -1) {
               type = "pdf";
            } else if (["ppt", "pptx"].indexOf(type) !== -1) {
               type = "ppt";
            } else if (["doc", "docx"].indexOf(type) !== -1) {
               type = "word";
            } else if (["csv", "xls", "xlsx"].indexOf(type) !== -1) {
               type = "excel";
            } else if (["txt"].indexOf(type) !== -1) {
               type = "txt";
            } else {
               type = "file";
            }
         }
         return type;
      },
   },
   methods: {
      copyLink() {
         // let shareLink = `${location.origin}/article/${this.article.article_id}`
         let shareLink = this.discloseLink;

         const input = document.createElement("input");
         input.setAttribute("readonly", "readonly");
         input.setAttribute("value", shareLink);
         document.body.appendChild(input);
         if (/iPhone/.test(navigator.userAgent)) {
            input.setSelectionRange(0, 9999);
         } else {
            input.select();
         }
         if (document.execCommand("copy")) {
            document.execCommand("copy");
            this.$message({
               type: "success",
               message: this.$t("链接复制成功"),
            });
         }
         document.body.removeChild(input);
      },
      proceed(){
         let self = this;
         this.$refs.form.validate().then((valid)=>{
            return DisclosureAPI.CreateLink({file_id:self.file.file_id,phone_number:self.fields.receiverPhone,name:self.fields.receiver}).then((res)=>{ 
                  self.step += 1;
                  self.linkId = res.rec_id;
            })
         }).then((res)=>{
         })
      }
   },
   mounted() {},
};
