const resourcePath='@/store/modules/draft.js';import DraftAPI from '@/api/v3/draft';

let lastTitleEditTimestamp = 0;

const state = {
    drafts: [],
    draftsIdMap: {}
};

const getters = {

};

const actions = {
    getDrafts(context) {
        return new Promise((resolve, reject) => {
            DraftAPI.GetDrafts()
                .then((drafts) => {
                    let list = drafts.draft_list;
                    context.commit("updateDrafts", list);
                    resolve(list);

                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    createDraft(context, articleId) {
        return new Promise((resolve, reject) => {
            DraftAPI.CreateDraft(articleId)
                .then((draft) => {
                    context.commit("createDraft", draft);
                    resolve(draft);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    updateDraftTitle(context, {draft, title, timestamp}) {

        lastTitleEditTimestamp = timestamp;

        return new Promise((resolve, reject) => {

            let oldTitle = draft.draft_title;

            context.commit("updateDraftTitle", {draft, title});

            setTimeout(() => {
                if(lastTitleEditTimestamp === timestamp) {
                    DraftAPI.UpdateDraft(draft.draft_id, {draft_title: title})
                        .then((updatedDraft) => {
                            resolve(updatedDraft);
                        })
                        .catch((err) => {
                            context.commit("updateDraftTitle", {draft, oldTitle});
                            reject(err);
                        });
                } else {
                    resolve(draft);
                }
            }, 1000);
        });
    },
    deleteDraft(context, draftId) {
        return new Promise((resolve, reject) => {
            DraftAPI.DeleteDraft(draftId)
                .then(()=> {
                    context.commit("deleteDraft", draftId);
                    resolve();
                })
                .catch((err)=>{
                    reject(err);
                });
        });
    },
    publishDraft(context, {draftId, groupId, content}) {
        return new Promise((resolve, reject) => {
            DraftAPI.PublishDraft(draftId, groupId, content)
                .then((article) => {

                    context.commit("deleteDraft", draftId);

                    resolve(article);
                })
                .catch((err)=>{
                    reject(err);
                });
        });
    }
};

const mutations = {
    updateDrafts (state, drafts) {

        state.drafts = [];
        state.draftsIdMap = {};

        if(drafts) {
            state.drafts = drafts;

            state.drafts.forEach((draft) => {
                state.draftsIdMap[draft.draft_id] = draft;
            });
        }
    },
    createDraft (state, draft) {
        state.drafts.unshift(draft);
        state.draftsIdMap[draft.draft_id] = draft;
    },
    updateDraftTitle(state, {draft, title}) {
        draft.draft_title = title;
    },
    deleteDraft(state, draftId) {
        let draft = state.draftsIdMap[draftId];
        let draftIndex = state.drafts.indexOf(draft);

        if(draftIndex !== -1) {
            state.drafts.splice(draftIndex, 1);
            delete state.draftsIdMap[draftId];
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
