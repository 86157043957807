const resourcePath='@/config.js';export default {
    base_url: 'https://api.hassoroom.com',
    server_version: '/v1',
    server_v2: '/v2',
    server_v3: '/v3',
    hotnews_server_url: 'https://www.iyuanben.com/server_url',
    miks_oss_bucket_url:'haro.oss-ap-northeast-1.aliyuncs.com',
    appname:'hassoroom',
    sentry_dsn:'https://a0e4349b6b214603a3a1987d61ef9fde@sentry.yuanben.org/7',
    matrixAliasPostFix:'hassoroom.com'
}