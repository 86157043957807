const resourcePath='@/common/function/blankboard/blockregistry.js';import fastdiff from "fast-diff"
const MaterialTypeArticleEssay = 0
const MaterialTypeArticleMd    = 1
const MaterialTypeLink         = 2
const MaterialTypeFile         = 3
const MaterialTypeImage        = 4

export const ComponentTypeArticleBlock = 'articleblock'
export const ComponentTypeEssayBlock = 'essayblock'
export const ComponentTypeFileBlock = 'fileblock'
export const ComponentTypePictureBlock = 'pictureblock'
export const ComponentTypeCanvasBlock = 'canvasblock'
export const ComponentTypeEditorBlock = 'editorblock'
export const converters = {
   articleToMaterial:(article,materialGrp) => {
      return {
         resourceId:materialGrp + "_" + article.source_id,
         uniqueId:materialGrp + "_" + (article.material_id ? article.material_id : article.source_id),
         componentType : ComponentTypeArticleBlock,
         content_type:article.content_type,
         score:article.score,
         label:article.labels,
         componentData:{
            title:article.md_article.title,
            abstract:article.md_article.abstract,
            content_type:article.content_type,
            thumbnail:true,
            source_id:article.source_id,
            images:article.md_article.image_url,
            material_id:article.material_id || article.source_id
         },
         useScale:false,
         resizable:true,
         initialGridWidth:205,
         initialGridHeight:154,
         minWidth:205,
         minHeight:154
      }
   },
   essay_LinkToMaterial:(essay,materialGrp)=>{
      return {
         resourceId:materialGrp + "_" + essay.source_id,
         uniqueId:materialGrp + "_" + (essay.material_id ? essay.material_id : essay.source_id),
         componentType : ComponentTypeEssayBlock,
         content_type:essay.content_type,
         score:essay.score,
         label:essay.labels,
         componentData:{
            content:essay.essay_article ? essay.essay_article.content : null,
            content_type:essay.content_type,
            link:essay.link,
            thumbnail:true,
            labels:essay.labels,
            mentions:essay.mentions,
            color:essay.essay_article ? essay.essay_article.color : null,
            material_id:essay.material_id || essay.source_id
         },
         resizable:true,
         useScale:false,
         initialGridWidth:essay.link ? 209 :205,
         initialGridHeight:essay.link ? 209 :154,
         minWidth:essay.link ? 209 :205,
         minHeight:essay.link ? 209 :154,
      }
   },
   fileToMaterial:(file,materialGrp) => {
      return {
         resourceId:materialGrp + "_" + file.source_id,
         uniqueId:materialGrp + "_" + (file.material_id ? file.material_id : file.source_id),
         componentType : ComponentTypeFileBlock,
         content_type:file.content_type,
         score:file.score,
         label:file.labels,
         componentData:{
            content:file.essay_article ? file.essay_article.content : null,
            content_type:file.content_type,
            file:file.file,
            thumbnail:true,
            labels:file.labels,
            mentions:file.mentions,
            material_id:file.material_id || file.source_id
         },
         resizable:true,
         useScale:false,
         initialGridWidth:205,
         initialGridHeight:205,
         minWidth:205,
         minHeight:205,
      }
   },
   picture2Material:(pic,materialGrp) => {
      let initGridWidth = null,
          initGridHeight = null;
      if(pic.image.width && pic.image.height) {
         if(pic.image.width < pic.image.height) {
            initGridWidth = 200
            initGridHeight = Math.round(pic.image.height / pic.image.width * 200)
            if(initGridHeight > 1000) {
               initGridHeight = 1000
               initGridWidth = Math.round(pic.image.width / pic.image.height * 1000)
            }
         } else {
            initGridHeight = 200
            initGridWidth = Math.round(pic.image.width / pic.image.height * 200)
            if(initGridWidth > 1000) {
               initGridWidth = 1000
               initGridHeight = Math.round(pic.image.height / pic.image.width * 1000)
            }
         }
      } else {
         initGridHeight = 200;
         initGridWidth = 200;
      }
      return {
         resourceId:materialGrp + "_" +  pic.source_id,
         uniqueId:materialGrp + "_" + (pic.material_id ? pic.material_id : pic.source_id),
         componentType : ComponentTypePictureBlock,
         content_type:pic.content_type,
         score:pic.score,
         label:pic.labels,
         componentData:{
            content_type:pic.content_type,
            thumbnail:true,
            source_id:pic.source_id,
            image:pic.image,
            mentions:pic.mentions,
            material_id:pic.material_id || pic.source_id
         },
         resizable:true,
         initialGridWidth:initGridWidth,
         initialGridHeight:initGridHeight
      }
   }
}
export const convertComponentData = function(type,componentData,yDocMap) {
   switch(type){
      case ComponentTypeArticleBlock:
         return new Proxy(componentData,{
            set(obj,key,value) {
               console.log('store Y Doc')
               obj[key] = value;
               yDocMap.set(key,value)
               return true
            }
         })
      case ComponentTypeEssayBlock:
         if(componentData.link) {
            let linkContent = new Proxy(componentData.link,{
               set(obj,key,value) {
                  console.log('store Y Doc Link')
                  obj[key] = value;
                  yDocMap.get('link').set(key,value)
                  return true
               }
            })
            componentData.link = linkContent
         }
         return new Proxy(componentData,{
            set(obj,key,value) {
               console.log('store Y Doc')
               if(key === 'content') {
                  let oldV = obj[key],
                      diff = fastdiff(oldV,value),
                      cursor = 0,
                      originYText = yDocMap.get(key)
                  diff.forEach((itm)=>{
                     if(itm[0] == 0)
                        cursor += itm[1].length
                     else if(itm[0] == 1) {
                        originYText.insert(cursor,itm[1])
                        cursor += itm[1].length
                     }
                     else
                        originYText.delete(cursor,itm[1].length)
                  })
               } else {
                  yDocMap.set(key,value)
               }
               obj[key] = value;
               return true
            }
         })
      case ComponentTypeFileBlock:
         let fileContent = new Proxy(componentData.file,{
            set(obj,key,value){
               console.log('store Y Doc File')
               obj[key] = value;
               yDocMap.get('file').set(key,value)
               return true
            }
         })
         componentData.file = fileContent
         return new Proxy(componentData,{
            set(obj,key,value) {
               console.log('store Y Doc')
               obj[key] = value;
               yDocMap.set(key,value)
               return true
            }
         })
      case ComponentTypePictureBlock:
         return new Proxy(componentData,{
            set(obj,key,value) {
               console.log('store Y Doc')
               obj[key] = value;
               yDocMap.set(key,value)
               return true
            }
         })
      case ComponentTypeCanvasBlock:
         return new Proxy(componentData,{
            set(obj,key,value) {
               console.log('store Y Doc')
               obj[key] = value;
               yDocMap.set(key,value)
               return true
            }
         })
      case ComponentTypeEditorBlock:
         return new Proxy(componentData,{
            set(obj,key,value) {
               console.log('store Y Doc')
               obj[key] = value;
               yDocMap.set(key,value)
               return true
            }
         })
   }
}
export const registedMap = {
   [MaterialTypeArticleEssay]:{
      converter:converters['essay_LinkToMaterial']
   },
   [MaterialTypeArticleMd]:{
      converter:converters['articleToMaterial']
   },
   [MaterialTypeLink]:{
      converter:converters['essay_LinkToMaterial']
   },
   [MaterialTypeFile]:{
      converter:converters['fileToMaterial']
   },
   [MaterialTypeImage]:{
      converter:converters['picture2Material']
   }
}
export const dto2Material = (dto,matieralGrp) => {
   return  (registedMap[dto.content_type] !== null && registedMap[dto.content_type] !== undefined) ? registedMap[dto.content_type].converter(dto,matieralGrp) : null
}