const resourcePath='@/store/modules/mention.js';const state = {
   mentionData:{
      group_id:null,
      mentions_style:null,
      mentions_range:null,
      mentionsCallback:null
   }
};
const getters = {
};
const actions = {}
const mutations = {
   updateMentionGroupId(state,group_id) {
       state.mentionData.group_id = group_id
   },
   updateMentionStyle(state,mentions_style) {
      state.mentionData.mentions_style = mentions_style;
   },
   updateMentionRange(state,mentions_range) {
      state.mentionData.mentions_range = mentions_range;
   },
   updateMentionCallback(state,mentions_callback) {
      state.mentionData.mentionsCallback = mentions_callback;
   }
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
};
