const resourcePath='@/api/v4/collaborative.js';import {httpClient} from './v4';

let CollaborativeAPI = {
    CreateCollaborativeDoc: (groupId) => {
        return httpClient.post('/collaborative-docs', {group_id: groupId})
    },
    GetCollaborativeDocs: (params) => {
        return httpClient.get('/collaborative-docs', {params})
    },
    UpdateCollaborativeDoc: (collaborativeDoc) => {
        return httpClient.put('/collaborative-docs/' + collaborativeDoc.doc_id, collaborativeDoc)
    },
    DeleteCollaborativeDoc: (collaborativeId) => {
        return httpClient.delete('/collaborative-docs/' + collaborativeId)
    },
    GetCollaborativeDoc: (collaborativeId) => {
        return httpClient.get('/collaborative-docs/' + collaborativeId)
    },
    PublishCollaborativeDoc:(doc_id,data) => {
        return httpClient.post(`/collaborative-docs/${doc_id}/article`,data)
    },
    WebSocketSyncContent: (collaborativeId) => {

        let endpoint = httpClient.defaults.baseURL + "/collaborative-docs/ws";

        if(!/^(https|http)/.test(endpoint)) {
            // relative path
            let hostname = window.location.href.split("/");

            endpoint = hostname[0] + "//" + hostname[2] + endpoint;
        }

        endpoint = endpoint.replace(/^http/, "ws");

        let sessionKey = localStorage.getItem("session_key");

        if(sessionKey) {
            endpoint = endpoint + "?doc_id=" + collaborativeId + "&session_key=" +sessionKey;
        }

        return endpoint;
    }
}

export default CollaborativeAPI;
