const resourcePath='@/filters/text2highlight.js';export default (text, highlight) => {
    if (!text) return;
    //highlight 转高亮
    if (highlight && highlight.length) {
        highlight.forEach(item=>{
            let reg_highlight = new RegExp(item,'igm');
            text = text.replace(reg_highlight, function (match, p1, p2, p3) {
                return `<span class="highlight">${match}</span>`
            })
        })
    }

    return text
}