const resourcePath='@/common/function/dayjs.js';import Vue from 'vue'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn' // load on demand
import 'dayjs/locale/ja' // load on demand

import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

// dayjs.locale('zh-cn');
// dayjs.locale('ja');

// if (localStorage.getItem('')) {
//     dayjs.locale('zh-cn');
// } else {
//     dayjs.locale('ja');
// }

var language = (navigator.browserLanguage || navigator.language).toLowerCase();

if (language.indexOf('ja') > -1) {
    dayjs.locale('ja');
} else {
    dayjs.locale('zh-cn');
}


Object.defineProperty(Vue.prototype, '$dayjs', { value: dayjs });

// 时间格式化
Vue.prototype.timeFormat = (timestamp) => {
    let text = '';
    if (!timestamp) return ''

    // let stamp = new Date(new Date().setHours(0, 0, 0, 0));
    let stamp = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
    let interval = (stamp - timestamp) / (24 * 60 * 60 *1000);
    if (interval > 0) {
        if (interval <= 1) {
            text = Vue.prototype.$t('昨天') + ' ' + dayjs(timestamp).format('HH:mm')
        } else if (interval <= 2) {
            text = Vue.prototype.$t('前天') + ' ' + dayjs(timestamp).format('HH:mm')
        } else {
            if (new Date().getFullYear() == new Date(timestamp).getFullYear()) {
                if (language.indexOf('ja') > -1) {
                    text = dayjs(timestamp).format('MM月DD日 HH:mm')
                } else {
                    text = dayjs(timestamp).format('MM-DD HH:mm')
                }
            } else {
                if (language.indexOf('ja') > -1) {
                    text = dayjs(timestamp).format('YYYY年MM月DD日 HH:mm')
                } else {
                    text = dayjs(timestamp).format('YYYY-MM-DD HH:mm')
                }
            }
        }
    } else if(Math.abs(timestamp - Date.now()) < 3600 * 1000) {
        text =  Vue.prototype.$t('现在')
    } else {
        text = dayjs(timestamp).fromNow()
    }

    return text
}
