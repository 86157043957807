const resourcePath='@/components/face.vue';//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: '',
    props: {
        author: {
            type: Object,
            required: true,
            default: {
                name: 'X',
                out_uid: '',
                head_img: null
                // head_img: {
                //     storage_url: ''
                // },
            }
        },
        type: {
            type: String,
            default: 'circle'
        },
        size: {
            type: String,
            default: 'normal'
        },
        link: {
            type: Boolean,
            default: true
        }
    },
    components: {

    },
    computed: {
        faceUrl() {
            return this.author.head_img.storage_url + '?x-oss-process=image/resize,m_fill,w_80,h_80,limit_0'
        }
    },
    data() {
        return {}
    },
    methods: {

    }
}
