const resourcePath='@/api/v3/material.js';import {httpClient} from './v3';

const MaterialAPI = {
    createMaterial: (data) => {
        return httpClient.post('/material', data);
    },
    getMaterials: (data) => {
        return httpClient.get('/material', {params: data});
    },
    deleteMaterial: (sourceId) => {
        return httpClient.delete('/material/' + sourceId);
    }
}

export default MaterialAPI;