const resourcePath='@/router.js';import Vue from 'vue'
import Router from 'vue-router'

import axios from 'axios'
import API_organization from '@/api/organization'
import {
   addGlobalEventProcessor
} from '@/common/function/eventCapture.js'
// 解决点击相同路由报错 (路由多次重定向报错) (replace报错时，用push代替)
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

//faces 未登录 外界可访问 一般宣传展示页面
const faces = () => import( /* webpackChunkName: "faces" */ './views/faces/index.vue');
const product = () => import( /* webpackChunkName: "product" */ './views/faces/product/index.vue');
const price = () => import( /* webpackChunkName: "price" */ './views/faces/price/index.vue');
const login = () => import( /* webpackChunkName: "login" */ './views/faces/login/login.vue');
const register = () => import( /* webpackChunkName: "register" */ './views/faces/register/register.vue');
const node_pick = () => import( /* webpackChunkName: "node_pick" */ './views/faces/node-pick/index.vue');
const find_password = () => import( /* webpackChunkName: "find_password" */ './views/faces/find-password/index.vue');
const find_password_reset = () => import( /* webpackChunkName: "find_password_reset" */ './views/faces/find-password/reset/index.vue');
const find_password_send = () => import( /* webpackChunkName: "find_password_send" */ './views/faces/find-password/send/index.vue');
const boardViewport = () => import( /* webpackChunkName: "blankboard" */ './views/blankboard/viewport/index.vue');
//用户协议
const agreement = () => import( /* webpackChunkName: "agreement" */ './views/faces/agreement/index.vue');

//隐私政策
const privacy = () => import( /* webpackChunkName: "privacy" */ './views/faces/agreement/privacy.vue');

//dashboard 必须登录
const dashboard = () => import( /* webpackChunkName: "dashboard" */ './views/dashboard/dashboard.vue');
const group_list = () => import( /* webpackChunkName: "groupList" */ './views/dashboard/group-list/index.vue');
// const group_list = () => import(/* webpackChunkName: "test" */'./views/dashboard/group-list/group-list.vue');

const profile = () => import( /* webpackChunkName: "profile" */ './views/dashboard/profile/index.vue');
const collect = () => import( /* webpackChunkName: "collect" */ './views/dashboard/collect/index.vue');
const notification = () => import( /* webpackChunkName: "notification" */ './views/dashboard/notification/index.vue');
const author = () => import( /* webpackChunkName: "author" */ './views/dashboard/author/index.vue');
const search = () => import( /* webpackChunkName: "search" */ './views/dashboard/search/index.vue');

const topic = () => import( /* webpackChunkName: "topic" */ './views/dashboard/topic/index.vue');

//个人中心
const account = () => import( /* webpackChunkName: "account" */ './views/dashboard/account/index.vue');
const personal = () => import( /* webpackChunkName: "personal" */ './views/dashboard/account/components/personal/index.vue');
const team = () => import( /* webpackChunkName: "team" */ './views/dashboard/account/components/team/index.vue');
const mall = () => import( /* webpackChunkName: "mall" */ './views/dashboard/account/components/mall.vue');

// quill编辑器
const edit = () => import( /* webpackChunkName: "editorQuill" */ './views/dashboard/editor/index.vue');
const collaborative_edit = () => import( /* webpackChunkName: "collaborative_edit" */ './views/dashboard/editor/collaborative/index.vue');

// 协同文章详情
const collaborative_doc_detail = () => import( /* webpackChunkName: "articleCollaboration" */ './views/pages/collaborative-doc/index.vue');

const mdEditor = () => import( /* webpackChunkName: "mdEditor---" */ './views/dashboard/editor/markdown/index.vue');
const mdEditorUpdate = () => import( /* webpackChunkName: "updateEditor___" */ './views/dashboard/editor/markdown/update.vue');


const explore = () => import( /* webpackChunkName: "explore" */ './views/dashboard/explore/index.vue');
const explore_topics = () => import( /* webpackChunkName: "explore-toplics" */ './views/dashboard/explore/views/topics.vue');
const explore_articles = () => import( /* webpackChunkName: "explore-articles" */ './views/dashboard/explore/views/articles.vue');
const explore_groups = () => import( /* webpackChunkName: "explore-groups" */ './views/dashboard/explore/views/groups.vue');
const primas_bind = () => import( /* webpackChunkName: "primas_bind" */ './views/dashboard/primas-bind/index.vue');
const staff = () => import( /* webpackChunkName: "staff" */ './views/dashboard/staff/index.vue');
const certification = () => import( /* webpackChunkName: "certification" */ './views/dashboard/certification/index.vue');
const payment = () => import( /* webpackChunkName: "payment" */ './views/dashboard/payment/index.vue');
const organization = () => import( /* webpackChunkName: "organization" */ './views/dashboard/organization/index.vue');
const organization_order = () => import( /* webpackChunkName: "organization-order___" */ './views/dashboard/organization/order/index.vue');
const organization_staff = () => import( /* webpackChunkName: "organization-staff___" */ './views/dashboard/organization/staff/index.vue');
const organization_group = () => import( /* webpackChunkName: "organization-group___" */ './views/dashboard/organization/group/index.vue');

// 非内页
const organization_create = () => import( /* webpackChunkName: "organization-create" */ './views/dashboard/organization/create/index.vue');
const organization_invite = () => import( /* webpackChunkName: "organization-invite" */ './views/dashboard/organization/create/invite.vue');
const join_organization = () => import( /* webpackChunkName: "join_organization" */ './views/dashboard/organization/create/join.vue');

// const mall = () => import(/* webpackChunkName: "mall" */'./views/dashboard/mall/index.vue');
const orders = () => import( /* webpackChunkName: "orders" */ './views/dashboard/mall/orders/index.vue');
// const mall_admin = () => import(/* webpackChunkName: "mall_admin" */'./views/dashboard/mall/admin/index.vue');

//pages 一般是非dashboard 内页
const pages = () => import( /* webpackChunkName: "pages" */ './views/pages/index.vue');
const article = () => import( /* webpackChunkName: "article" */ './views/pages/article/index.vue');
const group_preview = () => import( /* webpackChunkName: "groupPreview" */ './views/pages/group-preview/index.vue');
const email_verify = () => import( /* webpackChunkName: "email_verify" */ './views/pages/email-verify/index.vue');
const hoteyes = () => import( /* webpackChunkName: "hoteyes" */ './views/pages/hoteyes/index.vue');
const hotnews = () => import( /* webpackChunkName: "hotnews" */ './views/pages/hoteyes/news-list/index.vue');
const searchList = () => import( /* webpackChunkName: "searchList" */ './views/pages/hoteyes/search-list/index.vue');
const news_article = () => import( /* webpackChunkName: "news_article" */ './views/pages/hoteyes/article/index.vue');

const article_share = () => import( /* webpackChunkName: "articleShare" */ './views/pages/share/index.vue');

const file_disclose = () => import( /* webpackChunkName: "file_expose" */ '@/views/pages/file-disclose/index.vue');

const sms_verify = () => import( /* webpackChunkName: "sms_verfiy" */ '@/views/pages/file-disclose/sms-identity-verify.vue')
//404页面
const notfound = () => import( /* webpackChunkName: "notfound" */ './views/notfound/notfound.vue');

const permissionDenied = () => import( /* webpackChunkName: "notfound" */ './views/permissionDenied/permissionDenied.vue');

const blockEditor = () => import( /* webpackChunkName: "blockEditor" */ './views/blockeditor/index.vue');
Vue.use(Router);
export const paths = [{
      path: '/product', //产品首页
      name: 'product',
      component: product
   },
   {
      path:'/index',
      redirect:'/'
   },
   {
      path: '/',
      name: 'faces',
      component: faces,
      redirect: {
         name: 'product'
      },
      children: [
         {
            path: '/price',
            name: 'price',
            component: price
         },
         {
            path: '/node-pick', //节点列表
            name: 'node-pick',
            component: node_pick
         },
         {
            path: '/agreement',
            name: 'agreement',
            component: agreement
         },
         {
            path: '/privacy',
            name: 'privacy',
            component: privacy
         },
         {
            path: '/login',
            name: 'login',
            component: login,
            meta: {
               keepAlive: true
            }
         },
         {
            path: '/register',
            name: 'register',
            component: register
         },
         {
            path: '/find-password', //找回密码
            name: 'find_password',
            component: find_password,
            children: [{
                  path: 'reset',
                  name: 'find_password_reset',
                  component: find_password_reset,
               },
               {
                  path: 'send',
                  name: 'find_password_send',
                  component: find_password_send,
               }
            ]
         },
      ]
   },
   {
      path: '/blankboard/:group_id/:board_id',
      name: 'blankboard',
      component: boardViewport,
      meta: {
         requireAuth: true, //路由需要登录
      }
   },
   {
      path: '',
      name: 'dashboard',
      component: dashboard,
      redirect: {
         name: 'group'
      },
      meta: {
         requireAuth: true //路由需要登录
      },
      children: [
         {
            path: '/group/:id/:article_id?', //圈子列表
            name: 'group',
            component: group_list
         },
         {
            path: '/group/:id?', //圈子列表
            name: 'group',
            component: group_list
         },
         {
            path: '/group/:id/picks',
            name: 'group-picks',
            component: group_list
         },
         {
            path: '/group/:id/docs',
            name: 'group-docs',
            component: group_list
         },
         {
            path: '/group/:id/files',
            name: 'group-files',
            component: group_list
         },
         {
            path: '/group/:id/blankboards',
            name: 'group-blankboards',
            component: group_list
         },
         {
            path: '/group/:id/statistics',
            name: 'group-statistics',
            component: group_list
         },
         {
            path: '/group/:id/search/:searchText',
            name: 'group-search',
            component: group_list
         },
         {
            path: '/account',
            name: 'account',
            component: account,
            redirect: {
               name: 'personal'
            },
            children: [{
                  path: 'personal',
                  name: 'personal',
                  component: personal
               },
               {
                  path: 'settings',
                  name: 'settings',
                  component: profile
               },
               {
                  path: 'collections',
                  name: 'collections',
                  component: collect
               },
               {
                  path: 'coupons',
                  name: 'coupons',
                  component: orders
               },
               {
                  path: 'team',
                  name: 'team',
                  component: team
               },
               {
                  path: 'mall',
                  name: 'mall',
                  component: mall
               }
            ]
         },
         {
            path: '/author/:id', //作者主页
            name: 'author',
            component: author
         },
         {
            path: '/profile', //个人中心
            name: 'profile',
            component: profile
         },
         {
            path: '/collect', //收藏
            name: 'collect',
            component: collect
         },
         {
            path: '/topic', //收藏
            name: 'topic',
            component: topic
         },
         {
            path: '/group/:id/edit', //富文本编辑器
            name: 'edit',
            component: edit
         },
         {
            path: '/group/:id/collaborative-edit', //协同编辑
            name: 'collaborativeEdit',
            component: collaborative_edit
         },
         {
            path: '/group/:id/editorMarkdown', //markdown编辑器
            name: 'editorMarkdown',
            component: mdEditor
         },
         // {
         //     path: '/editorMarkdown',  //markdown编辑器
         //     name: 'editorMarkdown',
         //     component: mdEditor
         // },
         {
            path: '/editorMarkdownUpdate', //更新
            name: 'editorMarkdownUpdate',
            component: mdEditorUpdate
         },

         {
            path: '/notification', //通知列表
            name: 'notification',
            component: notification
         },
         {
            path: '/explore', //圈子发现
            name: 'explore',
            component: explore,
            redirect: {
               name: 'explore/articles'
            },
            // children: [
            //     {
            //         path: 'enterprise',
            //         name:'exploreEnterprise',
            //         component: explore_enterprise
            //     },
            //     {
            //         path: 'outside',
            //         name: 'exploreOutside',
            //         component: explore_outside
            //     }
            // ]
            children: [{
                  path: 'topics',
                  name: 'explore/topics',
                  component: explore_topics
               },
               {
                  path: 'articles',
                  name: 'explore/articles',
                  component: explore_articles
               },
               {
                  path: 'groups',
                  name: 'explore/groups',
                  component: explore_groups
               }
            ]
         },

         {
            path: '/primas-bind', //primas账户绑定
            name: 'primas-bind',
            component: primas_bind
         },
         {
            path: '/staff', //成员列表
            name: 'staff',
            component: staff
         },
         {
            path: '/organization',
            name: 'organization',
            component: organization,
            children: [{
                  path: 'order',
                  name: 'organizationOrder',
                  component: organization_order
               },
               {
                  path: 'staff',
                  name: 'organizationStaff',
                  component: organization_staff
               },
               {
                  path: 'group',
                  name: 'organizationGroup',
                  component: organization_group
               }
            ]
         },
         {
            path: '/certification', //企业认证
            name: 'certification',
            component: certification
         },
         {
            path: '/search/:searchText', //搜索
            name: 'search',
            component: search
         },
         {
            path: '/payment', //认证付费
            name: 'payment',
            component: payment
         }
      ]
   },
   {
      path: '',
      name: 'pages',
      component: pages,
      children: [
         {
            path: '/editor/:id', //圈子列表
            name: 'blockEditor',
            component: blockEditor
         },
         {
            path: '/article/:id', //文章详情页
            name: 'article',
            component: article
         },
         {
            path: '/collaborative-doc/:id',
            name: 'collaborativeDocDetail',
            component: collaborative_doc_detail
         },

         {
            path: 'article/share/:id',
            name: 'article_share',
            component: article_share
         },
         {
            path: '/group-preview/:id', //预览分享的小组
            name: 'group-preview',
            component: group_preview
         },
         {
            path: '/email-verify', //邮件验证成功结果页面
            name: 'email-verify',
            component: email_verify
         },
         {
            path: '/organization-create',
            name: 'organization-create',
            component: organization_create
         },
         {
            path: '/organization-invite',
            name: 'organization-invite',
            component: organization_invite
         },
         {
            path: '/joinOrganization/:id',
            name: 'joinOrganization',
            component: join_organization
         },
         {
            path: '/hoteyes',
            name: 'hoteyes',
            component: hoteyes,
            meta: {
               requireAuth: true, //路由需要登录
            },
            children: [{
                  path: '/hotnews/query',
                  name: 'hotnewsQuery',
                  component: searchList,
               },
               {
                  path: '/hotnews/:tag',
                  name: 'hotnews',
                  component: hotnews,
               },
               {
                  path: '/news-article/:id',
                  name: 'news-article',
                  component: news_article,
               }
            ]
         },
         {
            path: '/file-disclose/:id',
            component: file_disclose,
            children: [{
               name: 'sms-verify',
               path: 'sms-verify',
               component: sms_verify
            }]
         }
      ]
   },
   {
      path: '/401',
      name: 'permissionDenied',
      component: permissionDenied
   },
   {
      path: '/404',
      name: 'notfound',
      component: notfound
   },
   {
      path: '*',
      redirect: '/404'
   }
]
const router = new Router({
   mode: 'history',
   scrollBehavior(to, from, savedPosition) {
      // keep-alive 返回缓存页面后记录浏览位置
      if (savedPosition && to.meta.keepAlive) {
         return new Promise((resolve, reject) => {
            setTimeout(() => {
               resolve(savedPosition)
            }, 500)
         })
      }
      // 异步滚动操作
      return new Promise((resolve, reject) => {
         setTimeout(() => {
            resolve({
               x: 0,
               y: 0
            })
         }, 500)
      })
   },

});
router.beforeEach((to, from, next) => {
   console.log(window.location.href)
   // let session_key = 'd1252a70750d6f4441e8b9ca2a6c0193d50f9e85906c00d29919a61e9fa45d48'; //无企业
   // let session_key = 'a660a034cf3fc8b8ec36183bc44c99e9613f7177c066e1707f9fc592e8c7f5c1'; //有企业
   // localStorage.setItem('session_key', session_key);
   // axios.defaults.headers.common['SessionKey'] = session_key;

   // 让页面回到顶部
   console.log('beforeEach')
   if (to.matched.some(record => record.meta.requireAuth)) { // 判断该路由是否需要登录权限
      if (!localStorage.getItem('session_key') && window.location.href.indexOf('session_key') == -1) {
         next({
            path: '/login',
            query: {
               redirect: to.fullPath
            } // 将跳转的路由path作为参数，登录成功后跳转到该路由
         })
         next()
      } else {
         if (window.location.href.indexOf('session_key') > -1 && window.location.href.indexOf('/profile') == -1) {
            let session_key = window.location.href.split('session_key=')[1];
            localStorage.setItem('session_key', session_key);
            axios.defaults.headers.common['SessionKey'] = session_key;
            axios.get(API_organization.common_url)
               .then(res => {
                  let orgList = res.data.data.orgs;

                  if (orgList.length > 0) {
                     let org_id = orgList.slice(-1)[0].org_id;
                     localStorage.setItem('org_id', org_id);
                     axios.defaults.headers.common['OrgID'] = org_id;
                     addGlobalEventProcessor(function (event, hint) {
                        if (event.user)
                           event.user.username = org_id
                        else
                           event.user = {
                              username: org_id
                           }
                        return event
                     })
                  }
                  next();
               })
         } else {
            next();
         }
      }
   } else {
      if (window.location.href.indexOf('session_key') > -1) {
         let session_key = window.location.href.split('session_key=')[1];
         localStorage.setItem('session_key', session_key);
         axios.defaults.headers.common['SessionKey'] = session_key;

         axios.get(API_organization.common_url)
            .then(res => {
               let orgList = res.data.data.orgs;
               if (orgList.length > 0) {
                  let org_id = orgList.slice(-1)[0].org_id;
                  localStorage.setItem('org_id', org_id);
                  axios.defaults.headers.common['OrgID'] = org_id;
               }
               next();
            })
      } else {
         next();
      }
   }
})
export default router