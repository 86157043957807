const resourcePath='@/common/function/dynamicUtil.js';import {mapState} from 'vuex'
import dynamicCmp from '@/components/dynamicCmp.js'
export const createDynamicCssMixin = (css_key,default_css) => {
   return {
      props:{
         required_style:{
            type:Object,
            default:null
         }
      },
      computed:{
         css_style(){
            return this.required_style ? this.required_style :this.registed_css_style;
         },
         ...mapState({
            registed_css_style: (state) => {
               return state.dynamiccmp.registedCSS[css_key]
                  ? state.dynamiccmp.registedCSS[css_key]
                  : default_css;
            }
         })
      }
   }
}

export const createDynamicEntrance = (cmp_key,defaultCmp,props,mixins) => {
   let Dynamic = dynamicCmp(defaultCmp,props,mixins);
   return {
      props: {
         ...props,
         required_style:{
            type:Object,
            default:null
         }
      },
      render(h){
            let props = {
               ...this.$props,
               dynamiccmp_name:cmp_key
            }
            return (
               <Dynamic props={props} on={this.$listeners}></Dynamic>
            )
      }
   }
}