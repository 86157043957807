const resourcePath='@/components/scroll-loading.directive.js';// do not remove this line
document.querySelector('html').style.overflow = 'visible'
const insertedFunc = {
    insert_global(el, binding, vnode) {
        let $page = $(window);
        let height = $page.height();
        let type = `scroll.${binding.value.type}`;
        $page.on(type, () => {
            //getBoundingClientRect() 返回视口坐标中的元素位置
            let top = el.getBoundingClientRect().top;
            //console.log(top, height)
            if (top <= height) {
                binding.value.handler()
            }
        })
    },
    insert_scoped(el, binding, vnode) {
        let $el = $(el);
        let $page = $el.parents('.zmx-scroll');
        // console.log($el, $page)
        let height = binding.value.scrollHeight || $page.outerHeight();
        let type = `scroll.${binding.value.type}`
        $page.on(type, () => {
            //getBoundingClientRect() 返回视口坐标中的元素位置
            height = binding.value.scrollHeight || $page.outerHeight();
            let top = $el.position().top;   //需要设置父元素 position: relative
            // console.log(top, height)
            if (top <= height) {
                binding.value.handler()
            }
        }).on('mousewheel', function (event) {
            // 一些数据
            let scrollTop = $page[0].scrollTop,
                scrollHeight = $page[0].scrollHeight,
                height = $page[0].clientHeight;
            //console.log(event, scrollTop, scrollHeight, height)
            let delta = (event.originalEvent.wheelDelta) ? event.originalEvent.wheelDelta : -(event.originalEvent.detail || 0);

            if ((delta > 0 && scrollTop <= delta) || (delta < 0 && scrollHeight - height - scrollTop <= -1 * delta)) {
                // IE浏览器下滚动会跨越边界直接影响父级滚动，因此，临界时候手动边界滚动定位
                $page[0].scrollTop = delta > 0 ? 0 : scrollHeight;
                // 向上滚 || 向下滚
                event.preventDefault();
            }
        });

    },
}

function removeListener (el, binding) {
    let func = binding.value.getScroller;
    let $page = $(el).parents('.zmx-scroll');
    if(func)
        $page = func(el)
    if (binding.value.type === 'global') {
        $page = $(window)
    }
    $page.off(`scroll.${binding.value.type}`)
}

export default {
    inserted(el, binding, vnode) {
        let func = `insert_${binding.value.type}`;
        let vm = vnode.context
        if (vm.keep) {
            while (!vm.$vnode.data.keepAlive){
                vm = vm.$parent
            }
            vm.$options.activated = vm.$options.activated ? [...vm.$options.activated, () => insertedFunc[func](el, binding, vnode)]:[() => insertedFunc[func](el, binding, vnode)]
            vm.$options.deactivated = vm.$options.deactivated ? [...vm.$options.deactivated, () => removeListener(el, binding)]:[() => removeListener(el, binding)]
        } else {
            insertedFunc[func](el, binding, vnode)
        }
    },
    unbind(el, binding, vnode) {
        if (!vnode.context.keep) {
            removeListener(el, binding)
        }
    }
}
