var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "repost-modal",
    _vm._g(
      {
        attrs: {
          articleDraft: _vm.articleDraft,
          is_keyword: _vm.is_keyword,
          group: _vm.group,
          visible: _vm.visible
        },
        on: {
          "update:visible": function($event) {
            _vm.visible = $event
          },
          reposted: _vm.onRepost
        }
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }