const resourcePath='@/views/dashboard/components/article-link/index.vue';//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

 import defaultLinkBg from '@/directives/default-link-bg'
 import API_article from '@/api/article'
 import linkMark from '@/views/dashboard/components/link-mark.vue'
 import {mapState} from 'vuex'
 import { createDynamicCssMixin } from "@/common/function/dynamicUtil.js";
 import article_link_css from './css_modules/article-link.styl'
 const css_mixin = createDynamicCssMixin(
   "main.widget.article-link",
   article_link_css
);
 export default {
     name: '',
     props: {
         article: {
             required: true,
             type: Object
         },
         openable:{
            type:Boolean,
            default:true
         },
         showSource:{
            type:Boolean,
            default:true
         }
     },
     mixins:[css_mixin],
     directives: {
         defaultLinkBg,
     },
     components: {
         linkMark
     },
     computed: {
         ...mapState(['locale']),
         user() {
             return this.$store.state.user
         },
         linkSource() {
             let str1, str2;
             if (this.article.link.url) {
                 str1 = this.article.link.url.split('//')[0]
                 str2 = this.article.link.url.split('//')[1].split('/')[0]
             }
             return str2
         },
         linkAppearance1(){
            return this.locale == 'jp' && this.$route.name == 'explore'
         }
     },
     data() {
         return {
            is_link_error : false,
            initialized:false
         }
     },
     methods: {
         read_detail(event) {
             if(!this.openable) {
                event.preventDefault();
             }
             if(this.article.article_id) {
               this.$axios.get(API_article.article(this.article.article_id)).then((res) => {
                  this.article.is_read = 1;
                  console.log('PV数据统计')
               })
             }
         }
     },
     created() {
        const self = this;
        if(!this.article.link || !this.article.link.head_image_url) {
           self.is_link_error = true
           self.initialized = true;
        } else {
         let img = new Image();
         img.onload = function () {
            self.is_link_error = false
            self.initialized = true;
         }
         img.onerror = function () {
            self.is_link_error = true
            self.initialized = true
         }
           img.src = this.linkAppearance1 ? `${this.article.link.head_image_url}?x-oss-process=image/resize,m_fill,w_50,h_42,limit_0`
           :`${this.article.link.head_image_url}?x-oss-process=image/resize,m_fill,w_120,h_80,limit_0`
        }
     }
 }
