const resourcePath='@/service/mention.js';
import store from '@/store'
export default class MentionService{
    constructor(attachedCmp){
        this.component = attachedCmp
    }
    showMentionOnCurSelection(groupId,callback){
        let sel = window.getSelection();
        let range = sel.getRangeAt(0);
        this.showMentionOnRange(range,groupId,callback);
    }
    showMentionOnRange(range,groupId,callback){
         range.setStart(range.startContainer, Math.max(range.startOffset - 1,0));
         let boundingRect = range.getBoundingClientRect();
         let style = {
            display: "block",
            left: `${boundingRect.x + 12}px`,
            top: `${boundingRect.y - 16 + boundingRect.height / 2}px`,
         };
         store.commit('mention/updateMentionGroupId',groupId)
         store.commit('mention/updateMentionStyle',style)
         store.commit('mention/updateMentionRange',range),
         store.commit('mention/updateMentionCallback',callback)
    }
    hideMention(){
        store.commit('mention/updateMentionStyle',null)
    }
}