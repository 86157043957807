const resourcePath='@/api/group.js';import config from '@/config';

const common_url = config.server_version + '/groups';

const recommend_group = config.server_version + '/recommend';

const group_statistics = config.server_version + '/statistics';

const invite = config.server_version + '/invites';

// 待优化：封装到组织信息API
const staff = '/v2/user';

export default {
    common_url,
    grp_user_config(gid,uid){
      return `${common_url}/${gid}/users/${uid}`
    },
    users(gid){
        return `${common_url}/${gid}/users`
    },
    group(gid) {
        return `${common_url}/${gid}`
    },

    recommend_group,

    statistics(gid) {
        return `${group_statistics}/${gid}/summary`
    },
    statisticsTable(gid) {
        return `${group_statistics}/${gid}/data`
    },

    statisticsBadge(gid) {
        return `${group_statistics}/${gid}/badge`
    },
    badgeArticles(gid) {
        return `${group_statistics}/${gid}/badge/list`
    },
    invite,

    staff,

}