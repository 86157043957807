const resourcePath='@/components/scroll-loading.vue';//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import scrollLoading from './scroll-loading.directive'

export default {
    name: '',
    props: {
        type: {
            required: true,
            type: String,
        },
        is_empty: {
            required: false,
            type: Boolean,
        },
        scrollHeight: {
            required: false,
            type: Number,
        },
        endTextVisible: {
            required: false,
            default: true,
            type: Boolean,
        },
        itemsLength: {
            type: Number,
            required: true,
            default: 0
        },
        keep: Boolean
    },
    directives: {
        scrollLoading
    },
    data() {
        return {}
    },
    methods: {
        handleEvent() {
            // console.log('emit-load');
            this.$emit('load');
        }
    }
}
