const resourcePath='@/components/repost-modal/exports/repost-modal.vue';//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import articleLink from '@/views/dashboard/components/article-link/index.vue'

import scrollLoading from '@/components/scroll-loading.vue'
import mentions from '@/views/dashboard/components/mentions.vue'

import txtFilter from '@/common/function/txt-filter'

import API_article from '@/api/article'
import API_search from '@/api/search'

import {mapState} from 'vuex'
import MentionService from '@/service/mention.js'
import TopicHintService from '@/service/topic-hint.js'
export default {
    name: '',
    props: {
        group: {
            // required: true,
            type: Object,    //需要修改 props传过来的值
            default: null
        },
        is_keyword:{
           type: Boolean,    //需要修改 props传过来的值
            default: false
        },
        articleDraft: {
            type: Object,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    components: {
        articleLink,
        scrollLoading,
        mentions
    },
    computed: {
        ...mapState([
            'user',
            'isPhone'
        ]),
        isSeen: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
                // this.stepOne = true;

                // setTimeout(() => {
                //     this.stepOne = true;
                // }, 100)
            }
        },
        groupsPageParams() {
            return this.$store.state.groups.pageParams
        },
        groups() {
            return this.$store.state.groups.list.filter(item => {
                return (item.group_type !== 9 || (item.group_type == 9 && (item.is_creator == 1 || item.is_manage > 0)))
            })
        },
        title() {
            if (this.stepOne) {
                return this.$t('选择小组')
            } else {
                return this.$t('转发到：') + this.selectedGroup.name
            }
        }
    },
    data() {
        return {
            loading: false,
            stepOne: true,
            // stepOne: false,
            selectedGroup: {},

            publishReady: false,

            composing: false,
            isTopic: false,

            searchTopicsKeyword: '',
            temp_range: null,
        }
    },
    methods: {
        closed() {
            this.stepOne = true;
            this.$emit('close')
        },
        groupFace(url) {
            return url + '?x-oss-process=image/resize,m_fill,w_80,h_80,limit_0'
        },
        selectGroup(param) {
            this.selectedGroup = param;
            this.stepOne = false;

            let memberParams = {
                page: 0,
                page_size: 20,
                group_id: this.selectedGroup.group_id
            }

            this.$store.dispatch('getGroupMember', memberParams)
        },
        async loadGroups() {
            if (this.loading || this.groupsPageParams.page_size < 20) return
            
            // 浅拷贝
            let params = {
                ...this.groupsPageParams
            }

            this.loading = true;
            await this.$store.dispatch('getGroups', params);
            this.loading = false;
        },
        del() {
            //当删除的是@内容 直接把整个都给删了
            let sel = window.getSelection();
            let range = sel.getRangeAt(0);
            
            if (range.startContainer.parentNode.className === 'is-append-link') {
                let node = range.startContainer.parentNode;
                node.parentNode.removeChild(node);
            }
        },
        onCompositionstart() {
            this.$refs.refEditor.classList.remove('empty');

            this.composing = true;
        },
        onCompositionend() {
            this.composing = false;

            this.handle_input();
        },
        handle_input() {
            this.temp_range = window.getSelection().getRangeAt(0);

            if (this.composing) return

            if (event.data === '#') {
                // 已经显示推荐话题浮窗时，输入第二个#自动关闭推荐话题浮窗
                let tempStr = window.getSelection().extentNode.data;
                if (tempStr.match(/#/g) && tempStr.match(/#/g).length > 1) {
                    this.isTopic = false;
                    this.topicHintService.hideTopicHint()
                } else {
                    this.isTopic = true;
                }
            }

            let dataStr = event.data;
            let tempStr = window.getSelection().extentNode.data;

            //判断是否按删除键
            if (dataStr) {
                if (tempStr.match(/#/g) && tempStr.match(/#/g).length > 2) {
                    this.isTopic = false;
                }
            } else {
                this.topicHintService.hideTopicHint()
            }

            //截取话题关键字 
            let tempIndex = -1;
            let tempKeyword = '';
            if (tempStr) {
                tempIndex = tempStr.indexOf('#');
                if (tempIndex > -1) {
                    tempKeyword = tempStr.substring(tempIndex+1);
                }
            }

            if (this.isTopic === true && tempKeyword && dataStr) {
                console.log('开始搜索话题')

                this.searchTopicsKeyword = tempKeyword;
                this.topicHintService.showTopicHintOnCurSelection(this.selectedGroup.group_id,
                        this.searchTopicsKeyword,
                    this.insertTopic);
            }


            //输入的是@
            if (event.data === '@') {
                this.$nextTick(function () {
                    this.mentionService.showMentionOnCurSelection(this.selectedGroup.group_id,null)
                })
            }
            this.mentionService.hideMention();

            this.$refs.refEditor.classList.remove('empty');

            if (this.$refs.refEditor.innerText === '') {
                this.$refs.refEditor.classList.add('empty');
                this.publishReady = false;
                return
            }
            this.publishReady = true;
        },
        insertTopic(param,topics_range) {
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(topics_range);
            let endLength = this.searchTopicsKeyword.length,
                data = topics_range.startContainer.data,
                idxOfHash = data.lastIndexOf('#');
            topics_range.setStart(topics_range.startContainer, idxOfHash );
            topics_range.setEnd(topics_range.startContainer, idxOfHash + endLength + 1);

            topics_range.deleteContents();

            let insertTxt = document.createTextNode('#' + param + '#');
            topics_range.insertNode(insertTxt);

            window.getSelection().selectAllChildren(topics_range.commonAncestorContainer);//range 选择obj下所有子内容
            window.getSelection().collapseToEnd();//光标移至最后

            this.isTopic = false;
            this.topicHintService.hideTopicHint()
        },
        post() {
            if(this.selectedGroup.group_id === this.articleDraft.group_id && !this.is_keyword) {
               this.$message({
                    message: this.$t('不能转发至同一小组'),
                    type: 'warning'
                });
                this.stepOne = true
                return
            }
            if (!this.publishReady || this.loading) return

            //去掉首尾的br和空格
            if (!this.$refs.refEditor.innerText.replace(/(^\s+)|(\s+$)/ig, '')) {
                this.$message({
                    message: this.$t('不能为空'),
                    type: 'warning'
                });
                return
            }

            let text = this.$refs.refEditor.innerHTML;   //safari下 回车显示<br> chrome显示\n
            
            let params = this.filterParams(text);

            //解析出话题
            let regex = /#[^#]+#/g;
            let topicsTemp = params.content.match(regex);
            if (topicsTemp) {
                params.topics = topicsTemp.map(item => {
                    return item.replace(/#/g, '')
                })
            }

            this.loading = true;
            this.$axios.post(API_article.common_url, params)
                .then(res => {
                    let data = res.data.data;

                    this.loading = false;
                    this.publishReady = false;
                    this.$router.push({name: 'group', params: {id: this.selectedGroup.group_id}});
                    this.$emit('reposted');
                    this.$emit('close')
                })
                .catch(() => {
                    this.loading = false;
                })
        },

        filterParams(text) {
            text = text.replace(/&amp;/ig, '&');
            text = text.replace(/&nbsp;/ig, ' ');

            let params = {
                type: 2,
                content: '',
                group_id: this.selectedGroup.group_id,
                link: this.articleDraft.link.url,
                mentions: [],
                is_forward:1
            };

            //@链接 提取参数
            let reg_mentions = /<a class="is-append-link" target="_blank" href="\/author\/(\d+)">(@[^\n]+?)<\/a>/ig;

            text = text.replace(reg_mentions, function (match, out_uid, name) {
                let mention = {
                    name,
                    out_uid
                }

                params.mentions.push(mention)
                return name
            });

            params.content = txtFilter(text);

            return params
        }
    },
    created() {
        // let params = {
        //     ...this.groupsPageParams
        // }

        let params = {
            page: 0,
            page_size: 20,
            source: 1
        }

        this.$store.dispatch('getGroups', params)
        this.stepOne = this.is_keyword == false;
        if(this.is_keyword) {
           this.selectedGroup = this.group
        }
        this.mentionService = new MentionService(this);
        this.topicHintService = new TopicHintService(this);
    },
    mounted() {
        // this.$nextTick(() => {
        //     this.$refs.refEditor.classList.add('empty');
        // })

        // this.$refs.refEditor.classList.add('my-class')
        // console.log(this.$refs.refEditor.innerHTML)
        // console.log(this.$refs.refEditor.innerText)
        
    }
}
