const resourcePath='@/views/dashboard/components/link-mark.vue';//
//
//
//
//
//
//
//
//
//

import fullTextSelf from '@/directives/full-text-self'

export default {
    name: '',
    props:{
        mark:{
            required:true,
            type:Object
        },
    },
    directives:{
        fullTextSelf
    },
    components: {},
    data() {
        return {}
    },
    methods: {}
}
