const resourcePath='@/api/v3/topic.js';import {httpClient} from './v3';

const TopicsAPI = {
    getTopics: (params) => {
        params = params || {}
        return httpClient.get(`/topics`,{params:params.params||{page_size:20},cancelToken:params.cancelToken});
    },
    getGroupTopics:(group_id,page,page_size,topic) =>{
         return httpClient.get(`/topics/${group_id}`,{params:{page:page,page_size:page_size,topic:topic}});
    }
}

export default TopicsAPI;