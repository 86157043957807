const resourcePath='@/views/dashboard/dialogs/repost-dialog.vue';//
//
//
//

import RepostModal from '@/components/repost-modal/index.vue'
export default {
   components: {
      'repost-modal':RepostModal
   },
   props: {
      articleDraft: {
         type: Object
      },
      group:{
         type: Object,
         default: null
      },
      is_keyword:{
         type: Boolean,
         default: false
      },
      visible:{
         type: Boolean,
         default: false
      },
      onRepostHandler:{
         type: Function,
         default: null
      }
   },
   methods:{
      onRepost(){
         if(this.onRepostHandler){
            this.onRepostHandler();
         }
      }
   },
   watch:{
      visible:function(nV,oV){
         if(!nV){
            this.$emit('close')
         }
      }
   },
};
