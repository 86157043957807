var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: _vm.stepTitle,
        "custom-class": "create-dialog"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        close: _vm.createClose
      }
    },
    [
      !_vm.groupType
        ? _c("div", [
            _c("ul", { staticClass: "group-type company" }, [
              _c(
                "li",
                {
                  class: {
                    active: _vm.groupType === 1,
                    disabled: !_vm.can_create_discuss_group
                  },
                  on: { click: _vm.selectDiscuss }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../common/images/group-discuss.svg")
                    }
                  }),
                  _vm._v(" "),
                  _c("h5", [_vm._v(_vm._s(_vm.$t("讨论组")))]),
                  _vm._v(" "),
                  _vm.locale == "jp" ? _c("br") : _vm._e(),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "(" +
                        _vm._s(
                          Math.min(
                            _vm.discuss_group_count,
                            _vm.max_discuss_group_count
                          )
                        ) +
                        "/" +
                        _vm._s(_vm.max_discuss_group_count) +
                        ")"
                    )
                  ]),
                  _vm._v(" "),
                  _vm.user.in_type == 1
                    ? _c("p", { staticClass: "label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("小组成员讨论空间，可用于信息交流、学习共享")
                          )
                        )
                      ])
                    : _c("p", { staticClass: "label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("小组成员讨论空间，可用于信息交流、学习共享")
                          )
                        )
                      ])
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: {
                    active: _vm.groupType === 9,
                    userType: _vm.user.in_type == 2,
                    disabled: !_vm.can_create_blog_group
                  },
                  on: { click: _vm.selectSubscribe }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../common/images/group-subscribe.svg")
                    }
                  }),
                  _vm._v(" "),
                  _c("h5", [_vm._v(_vm._s(_vm.$t("订阅组")))]),
                  _vm._v(" "),
                  _vm.locale == "jp" ? _c("br") : _vm._e(),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "(" +
                        _vm._s(
                          Math.min(
                            _vm.blog_group_count,
                            _vm.max_blog_group_count
                          )
                        ) +
                        "/" +
                        _vm._s(_vm.max_blog_group_count) +
                        ")"
                    )
                  ]),
                  _vm._v(" "),
                  _vm.user.in_type == 1
                    ? _c("p", { staticClass: "label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("企业专属信息发布空间，可用于拓展品牌影响")
                          )
                        )
                      ])
                    : _c("p", { staticClass: "label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("企业专属信息发布空间，可用于拓展品牌影响")
                          )
                        )
                      ])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.groupType
        ? _c("div", { staticClass: "group-form" }, [
            _c("div", { staticClass: "top" }, [
              _c("div", { staticClass: "l-face" }, [
                !_vm.upload_succ_url
                  ? _c("img", {
                      attrs: {
                        src: require("../../../common/images/default-upload-face.png")
                      },
                      on: { click: _vm.upload_group_face }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.upload_succ_url
                  ? _c(
                      "div",
                      {
                        staticClass: "default-text",
                        on: { click: _vm.upload_group_face }
                      },
                      [_vm._v(_vm._s(_vm.$t("小组头像")))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.upload_succ_url
                  ? _c("img", {
                      attrs: { src: _vm.upload_succ_url },
                      on: { click: _vm.upload_group_face }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "progress" }, [
                  _vm._v(_vm._s(_vm.upload_percent))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "r-name" }, [
                _c("h6", [_vm._v(_vm._s(_vm.$t("小组名")))]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.createGroupParams.name,
                      expression: "createGroupParams.name"
                    }
                  ],
                  attrs: { type: "text", placeholder: _vm.$t("输入小组名称") },
                  domProps: { value: _vm.createGroupParams.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.createGroupParams,
                        "name",
                        $event.target.value
                      )
                    }
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "group-desc zmx-scroll empty",
                attrs: {
                  contenteditable: "true",
                  "data-placeholder": _vm.$t("小组描述（非必填）"),
                  id: "groupDesc"
                },
                on: { input: _vm.handle_input, paste: _vm.handle_paste }
              },
              [_c("br")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "group-limit" }, [
              _c("h6", [_vm._v(_vm._s(_vm.$t("开放权限")))]),
              _vm._v(" "),
              _c("ul", { staticClass: "group-limit-select" }, [
                _c(
                  "li",
                  {
                    class: { active: _vm.createGroupParams.public_type == 1 },
                    on: {
                      click: function($event) {
                        _vm.createGroupParams.public_type = 1
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "miks-icon miks-icon-weixuanze" }),
                    _vm._v(" "),
                    _c("p", { staticClass: "txt" }, [
                      _vm._v(_vm._s(_vm.$t("私密")) + "：")
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "tips" }, [
                      _vm._v(_vm._s(_vm.$t("仅组内成员可查看")))
                    ])
                  ]
                ),
                _vm._v(" "),
                _vm.user.in_type == 1
                  ? _c(
                      "li",
                      {
                        class: {
                          active: _vm.createGroupParams.public_type == 3
                        },
                        on: {
                          click: function($event) {
                            _vm.createGroupParams.public_type = 3
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "miks-icon miks-icon-weixuanze"
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "txt" }, [
                          _vm._v(_vm._s(_vm.$t("企业内公开")) + "：")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "tips" }, [
                          _vm._v(_vm._s(_vm.$t("企业内成员可查看")))
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.user.in_type == 1 &&
                _vm.user.out_uid == _vm.user.org_manager &&
                _vm.groupType == 9
                  ? _c(
                      "li",
                      {
                        class: {
                          active: _vm.createGroupParams.public_type == 4
                        },
                        on: {
                          click: function($event) {
                            _vm.createGroupParams.public_type = 4
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "miks-icon miks-icon-weixuanze"
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "txt" }, [
                          _vm._v(_vm._s(_vm.$t("公开")) + "：")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "tips" }, [
                          _vm._v(
                            _vm._s(_vm.$t("企业内成员和其他企业成员都可查看"))
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "footer" },
              [
                _c(
                  "div",
                  { staticClass: "btn", on: { click: _vm.createBackstep } },
                  [_vm._v(_vm._s(_vm.$t("上一步")))]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "btn",
                    attrs: { loading: _vm.loading },
                    on: { click: _vm.createPost }
                  },
                  [_vm._v(_vm._s(_vm.$t("完成")))]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-upload",
        {
          ref: "faceUpload",
          staticClass: "face-upload-box",
          attrs: {
            action: _vm.action_url,
            "before-upload": _vm.beforeUpload,
            headers: {
              SessionKey: _vm.$axios.defaults.headers.common.SessionKey
            },
            "on-progress": _vm.handleProgress,
            "on-success": _vm.handleSuccess,
            "with-credentials": true,
            accept: "image/jpeg,image/jpg,image/png",
            name: "files"
          }
        },
        [_c("el-button", { attrs: { id: "face-upload", type: "primary" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }