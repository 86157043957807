const resourcePath='@/filters/primas-text2html.js';import text2highlight from './text2highlight'

export default (text, mentions, highlight) => {
    if (!text) return;

    //先去多行，再转br
    text = text.replace(/(<[^>]*?>)/ig, '');

    //换行符转br
    text = text.replace(/[\n\r]/ig, '<br>');

    // text = text.replace(/(<br>){2,}/ig, '<br>');

    //多个换行转一个
    // text = text.replace(/(<[^>]*?>)/ig, '');

    //highlight 转高亮
    text = text2highlight(text, highlight)

    return text
}