const resourcePath='@/store/mutations.js';const mutations = {
    updateUser(state, payload) {
        state.user = payload
    },
    get_nodes(state, payload) {
        if (payload.length > 0) {
            state.nodes = payload
        }
    },
    update_is_phone(state, payload) {
        state.isPhone = payload
    },
    updatePageScrollFixed(state, payload) {
        state.page_scroll_fixed = payload
    },
    update_myGroups(state,payload) {
      state.myGroups = payload
    },
    replace_myGroups(state, payload) {
        let myGroups = payload.myGroups
        if (payload.page > 0) {
            //圈子数组去重
            myGroups = myGroups.filter((group)=> 
            state.myGroups.findIndex((itm)=>itm.group_id == group.group_id) < 0)
         state.myGroups = [...state.myGroups, ...myGroups]
        } else {
            state.myGroups = myGroups
        }
    },
    splice_myGroups(state, payload) {
        let idx = state.myGroups.findIndex(group => {
            return group.group_id === payload
        })
        state.myGroups.splice(idx, 1)
    },
    add_myGroups(state, payload) {
        state.myGroups.push(payload)
    },
    // 1.修改圈子信息(头像、名字)  2.点击小组 un_read 小红点消失
    update_single_group_in_myGroups(state, payload) {
        // state.myGroups.forEach(group => {
        //     if (group.group_id == payload.group_id) {
        //         group = payload  //直接修改不能响应式，要用变异方法
        //     }
        // })
        let idx = state.myGroups.findIndex(group => {
            return group.group_id === payload.group_id
        })
        
        state.myGroups.splice(idx, 1, payload)
    },
    

    addNewsTags(state, payload) {
        state.newsTags = payload
    },
    update_light_header(state, payload) {
        state.light_header = payload
    },


    // 当前文章操作菜单
    updateCurrentMenu(state, payload) {
        state.currentMenu = payload
    },
    

    // 小组基本信息
    updateGroupInfo(state, payload) {
        if (payload.latest_id) {
            state.currentGroup.latest_id = payload.latest_id
        }
        if (payload.data) {
            state.currentGroup.data = payload.data
        }
    },
    
    // 小组成员列表
    updateGroupMember(state, payload) {
        state.groupMember.pageParams = payload.pageParams

        if (payload.pageParams.page === 1) {
            state.groupMember.list = payload.list
            
        } else {
            state.groupMember.list.push(...payload.list)
        }
    },

    // 我的小组
    updateGroups(state, payload) {
        state.groups.pageParams = payload.pageParams

        if (payload.pageParams.page === 1) {
            state.groups.list = payload.list
        } else {
            state.groups.list.push(...payload.list)
        }
    },


    // 新手引导提示
    updateGuide(state, payload) {
        state.guide = payload
    },

    // 切换语言
    initLocale(state, payload) {
        state.locale = payload
    },

    // 小组dashboard一起写、文件
    groupDashboard(state, payload) {
        state.groupDashboard = payload
    },
    updateAppVersionInfo(state,payload) {
       state.appVersionInfo = payload
    },
    setOutDated(state,payload) {
       state.outdated = payload
    }

}

export default mutations
