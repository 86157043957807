const resourcePath='@/components/dynamicCmp.js';
import { mapState } from 'vuex'
export default (DefaultCmp,props,mixins)=>{
   return {
      props:{
         dynamiccmp_name:{
            type:String,
            required:true
         },
         ...DefaultCmp.props,
         ...props
      },
      mixins:[...(DefaultCmp.mixins ||[]),...(mixins || [])],
      computed:{
         ...mapState(['dynamiccmp'])
      },
      render(h) {
         let Component = this.dynamiccmp.registed[this.dynamiccmp_name];
         if(Component) {
            return (
               <Component props={this.$props} on={this.$listeners}></Component>
            )
         } else if(Component !== null) {
            return (<DefaultCmp props={this.$props} on={this.$listeners}></DefaultCmp>)
         } else {
            return null;
         }
      }
   }
}
