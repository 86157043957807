const resourcePath='@/assets/lang/jp.js';let common = {
    "协作交流": "全員コミュニケーション",
    "共同成长": "全員成長",
    "协作交流 · 共同成长": "イノベーション誘発プラットフォーム",
    "暂无数据": "データがありません",
    "暂无内容": "データがありません",
    "删除": "削除",
    "转入":"シェア先",
    "确认删除？": "このコメントを削除してもよろしいですか？",
    "提交": "確定",
    "发布": "投稿",
    "确定": "確定",
    "取消": "取り消し",
    "我是有底线的": "これ以上表示できるものがありません",
    "复制链接": "リンクをコピーする",
    "回复": "コメントに返信",
    "评论.回复":"がコメントに返信",
    "发送":"送信",
    "返回": "戻る",
    "内容": "投稿",
    "讲话":"アイデア",
    "主页.内容":"アイデア",
    "num内容": "件の投稿",
    "最近count投稿":"{count}件最近の投稿",
    "count投稿":"{count}件の投稿",
    "count内容": "{count}件の投稿",
    "count成员": "{count}名の参加者",
    "文件": "ファイル",
    "小组": "グループ",
    "用户": "アカウント",
    "人": "人",
    "年": "年",
    "天": "日",
    "查看更多": "さらに見る",
    "全部": "すべて",
    "上一步": "前へ",
    "完成": "作成",
    "确认修改": "変更",
    "修改成功": "変更しました",
    "邀请": "招待",
    "提示": "確認",
    "昨天": "昨日",
    "前天": "一昨日",
    "一周内": "１週間",
    "一个月内": "１ヶ月",
    "现在":"たった今",
    "@和评论":"@そしてコメント",
    "邀请你加入组织":"組織に参加するよう招待します",
    "请输入您的邮箱":"あなたのメールアドレスを入力してください",
    "立即加入":"今すぐ参加",
    "登录Miks和组织伙伴一起交流分享吧":"HARO にログインして、組織のパートナーと通信して共有します",
    "可前往下载Miks APP":"HARO APP をダウンロードする",
    "前往Miks网页版":"HARO の Web バージョンに移動します。",
    "还差一步，完成验证后即可加入":"メール認証してください",
    "请登录":"サインインしてください",
    "验证你的邮箱":"あなたの電子メールを確認します",
    "创建组织信息":"組織情報の作成",
    "创建成功，邀请组织成员":"正常に作成されました。組織のメンバーを招待してください",
    "输入团队组织名称":"チームの組織名を入力してください",
    "下一步":"次のステップ",
    "邀请并进入":"招待して入場",
    "输入成员邮箱":"メンバーのメールアドレスを入力",
    "跳过，稍后邀请":"スキップして後で招待",
    "请输入组织名称":"組織名を入力してください",
    "申请加入组织":"組織への参加を申し込む",
    "申请已提交":"申請書が提出されました",
    "申请已成功发送给组织管理员，前往Miks确认是否已加⼊成功":"アプリケーションは組織管理者に正常に送信されました。HARO に移動して、アプリケーションが正常に追加されたかどうかを確認してください。",
    "链接已失效":"リンクの有効期限が切れています",
    "链接已失效，你可以联系邀请你的⼈，以获取新链接":"リンクの有効期限が切れています。招待した人に連絡して、新しいリンクを取得してください",
    "组织成员已满，不可加入":"組織のメンバーがいっぱいで、参加できません",
    "申请加入失败":"参加申請に失敗しました",
    "企业人数已达上限，请前往Miks网页端续费!":"企業数が上限に達しています。更新するには、HARO サイトにアクセスしてください。",
    "当前企业付费名额已满":"現在の企業の有料割り当てがいっぱいです",
    "申请失败":"申請に失敗しました",
    "系统已在东京时间time完成版本迭代，如若无法正常使用Miks，请刷新一下页面，或退出账户重新登录":"システムは東京時間{time}時にバージョンの反復を完了しました。Miksを正常に使用できない場合は、ページを更新するか、アカウントからログアウトして再度ログインしてください。"
}

let header = {
    "学习型组织的进化利器": "イノベーション誘発プラットフォーム",
    "首页": "ホーム",
    "发现": "発見する",
    "升级": "アップデート",
    "登录": "ログイン",
    "注册": "登録",
    "登录注册": "登録",

    "个人中心": "マイページ",
    "我的内容": "アイデア",
    "我的收藏": "キープ",
    "我的订单": "マイオーダー",
    "我的组织": "組織・グループ",
    "退出登录": "ログアウト",

    "个人主页": "マイページ",
    "账号设置": "アカウント",
    "他创建的": "作成したグループ",

    "他的内容": "すべての投稿",

    "积分商城": "积分商城",
    'Miks':'HARO'
}

let guideTips = {
    "发现公开小组": "グループを探す",
    "创建团队小组": "グループを作る"
}

let search = {
    "搜索": "検索",
    "查看所有结果": "“{word}”の全ての結果を見る",
    "搜索结果": "検索結果",
    "综合": "すべて",
    "没有找到匹配结果，你可以再试一次": "一致する結果がありません。",
    "企业内内容": "組織内コンテンツ",
    "企业外内容": "外部コンテンツ",
    "组织内":"組織内",
    "范围":"範囲",
    "发布人":"投稿者",
    "不限":"全て"
}

let notification = {
    "互动": "ディスカッション",
    "通知": "お知らせ",
    "标记全部已读": "既読にする",
    "查看全部消息": "すべてのメッセージを見る",
    "评论已删除": "コメントが削除されました",
    "我的消息": "マイメッセージ",

    "评论了你的文章": "があなたのアイデアにコメントしました",
    "评论中@了你": "がコメントであなたを＠しました",
    "@了你": "があなたを＠しました",
    "回复了你的评论": "があなたのコメントに返信しました",
    "赞了你的文章": "があなたのアイデアにバッジを送りました",
    "赏了你的文章": "があなたのコメントに「ステキ！」しました",
    "赞了你的评论": "があなたのコメントにバッジを送りました",
    "评论了usercomment": "が{user} {comment} にコメントしました",
    "@和评论":"メンションと返信",
    "已将group转让给你": "が{group}の権限をあなたに移しました",
    "你被user加入group": "あなたは{user}を{group}に招待しました",
    "加入了group": "が{group}に参加しました",
    "邀请你加入group": "があなたを{group}に招待しました",
    "新增了count篇内容": "新しい投稿が{count}件アップされました",
    "你已成功加入组织organization": "{organization}に加入しました",
    "已成功加入组织organization": "{organization}に加入しました",
	"已将你从group小组移出": "{group}グループから退出しました",
    "删除了你的文章": "あなたのアイデアを削除しました",
    "已将group小组归档": "が{group}をアーカイブしました",
    "@你":"＠あなた",
    "提到":"メンション",
    "收起":"非表示にする",
    "展开":"もっと見る",
    "其它":"その他",
    "已读":"既読"
}

let loginRegister = {
    "邮箱注册": "アカウント登録",
    "忘记密码": "パスワードを忘れる",
    "忘记密码？": "パスワードを忘れた場合",
    "输入邮箱地址": "メールアドレスを入力してください",
    "输入账号密码": "パスワードを入力してください",
    "注册即表明阅读并同意": "利用規約に同意し登録する",
    "用户协议": "利用規約",
    "已有账号？去登录": "すでにアカウントをお持ちの方はログインしてください",

    "验证邮箱完成注册": "認証メールを送信しました",
    "请检查电子邮件以完成注册。": "メールを確認し、メール認証を行なってください。",
    "没有收到电子邮件？请尝试检查垃圾邮件文件夹": "メールが届かない場合は、念のため迷惑メールフォルダもご確認ください",

    "请查收您的收件箱": "メールを確認してください",
    "密码重置邮件已发送给": "パスワードリセット用のメールを送信しました",
    "未收到邮件？": "メールは受信できましたか？",
    "重新发送": "メールを再送する",
    "使用其他邮箱": "他のメールアドレスを使う",
    "已向你发送电子邮件，请检查邮箱": "メールを送信しましたので，確認してください。",

    "邮箱验证成功！": "メール認証が完了しました",
    "正在验证": "認証中です",
    "网页端": "ウェブ版",
    "直接使用": "利用する",

    "重置密码": "パスワードリセット",
    "新密码": "新しいパスワード",
    "重复": "確認用",
    "请输入新密码": "新しいパスワードを入力してください",
    "请再次输入新密码": "もう一度パスワードを入力してください",
    "两次密码不一致": "パスワードが一致しません",
    "修改成功，重新登录": "変更が完了しました。もう一度ログインしてください"
}

let formVerify = {
    "请填写完整表单": "必要事項を空欄に記入してください",
    "请输入邮箱": "メールアドレスを入力してください",
    "请输入密码": "パスワードを入力してください",
    "邮箱格式错误": "メールアドレスの形式が間違っています"
}

let group = {
    "私密": "グループのみに公開",
    "企业内公开": "会社全体に公開",
    "公开": "すべて公開",
    "count个成员": "{count}人のメンバー",
    "count人订阅": "{count}人がフォローしています",
    "暂无简介": "紹介文がありません",
    "非公开小组，加入可查看内容": "このグループは公開されていません。グループに参加すれば投稿を見ることができます。",
    "加入可查看更多": "参加すればより多くの投稿を見ることができます",
    "暂不支持查看其它组织内小组的内容": "他の組織のグループの投稿は見ることはできません",

    "加入小组": "グループに参加する",
    "加入成功": "グループに参加しました",
    "订阅小组": "フォローする",

    "订阅badge": "掲示板グループ",

    "内容发现": "記事",
    "企业小组": "組織内",
    "外部企业": "外部",
    "外部":"組織外",
    "含有外部人员的小组":"このグループには組織外のメンバーが参加しています。",
    "外部人员":"組織外メンバー",
    "订阅": "フォロー",
    "已订阅": "フォロー中",
    "加入": "参加",
    "已加入": "参加中",

    "退出小组": "グループから退出",
    "请先转让小组":"管理人権限をグループメンバーに移してください",
    "仅组内成员可查看": "グループのメンバーのみ見ることができます",
    "企业内成员可查看": "社内の全員が見ることができます",

    "小组成员": "グループメンバー",
    "组长": "管理人",
    "企业内成员和其他企业成员都可查看": "組織内外のすべての人が見ることができます",

    "企业总部不可更改小组权限": "組織の管理人がグループの権限を変更することはできません",
    "选择转让成员": "権限を移すメンバーを選ぶ",
    "请选择转让成员": "権限を移すメンバーを選んでください",
    "确认转让": "権限を移す",
    "转让成功": "権限を移しました",
    "转让失败，该成员作为组长的小组数量已达上限。" :'このメンバーは、作成できるグループ数が上限に達しており、管理人になれません',
    "邀请成员到": "メンバーを招待する",
    "组织成员邀请": "グループメンバーを招待する",
    "邮件邀请": "メールアドレスで招待",
    "链接邀请": "リンクで招待",
    "邮箱重复":"メールボックスが重複しています",
    "输入或粘贴邮箱地址":"メールアドレスを入力してください",
    "请设定您的密码":"パスワードを設定してください",
    "请输入正确的邮箱":"メールアドレスの形式が正しくありません",
    "支持批量输入邮箱,每行输入一个":"複数のメールアドレスの入力が可能です",
    "已邀请": "招待しました",
    "通过邮件快速邀请同事加入": "メールでメンバーを招待します",
    "通过分享复制链接，邀请成员加入": "リンクでメンバーを招待します",

    "时间": "時間",
    "活跃用户": "利用ユーザー",
    "新增内容": "新規投稿",
    "新增评论": "新しいコメント",
    "新增点赞": "新規いいね！",
    "请选择": "選んでください",
    "最近七天": "1週間",
    "半个月": "2週間",
    "一个月": "1ヶ月",
    "三个月": "3ヶ月",
    "半年": "6ヶ月",
    "30天趋势": "直近１ヶ月の集計",

    "小组描述（非必填）": "グループの紹介（入力は必須ではありません）",
    "输入小组名称": "グループ名を入力してください",
    "输入小组名":"グループ名",
    "输入小组简介（非必填）":"グループの説明（任意）",
    "输入邮箱": "メールアドレスを入力してください",

    "解散后小组被删除，此操作不可撤回，是否确认解散？": "解散後はグループが削除されます。この操作は戻すことができませんが、解散しますか",
    "是否确认退出小组": "グループを退出しますか",
    "总部": "本部",

    "小组已归档": "アーカイブ済み",
    "组长已于": "管理人が",
    "将小组归档。小组内容依然可被搜索、浏览、点赞或评论，但无法加入和添加成员": "にアーカイブ。このグループに新しいメンバーを追加することはできません",
    "归档后可在个人页面我的组织里查看已经归档的小组，小组将不可再见。但小组内容依然可被搜索、浏览、点赞或评论。无法加入和添加成员。":"グループをアーカイブすると、ホーム画面左のグループリストから削除されますがよろしいですか？（グループのすべての記録はメンバーの個人ページにアーカイブされます）",
    "选择小组类型": "グループの種類を選択してください",
    "创建讨论组": "ディスカッショングループを作る",
    "创建订阅组": "掲示板グループを作る",
    "创建":"作成",
    "请上传小组头像": "イメージをアップロードしてください",
    "请输入小组名称": "グループ名を入力してください",
    "小组名称不能超过12个字符": "グループ名は12文字以内で設定してください",
    "小组头像": "グループイメージ",

    "转发": "シェア",
    "转发到：": "シェアするグループ：",
    "不能转发至同一小组":"同じグループ内にはシェアできません",
    "输入你的看法": "コメントを入力してください",
    "我的小组":"マイグループ ",
    "组织室":"組織",
    "项目室":"参加しているグループ",
    "建立群组":"グループを作る",
    "圈子简介":"グループの紹介",
    "人气圈子":'話題のアイデア',
    "人气话题":"人気のタグ",
    "全部话题":"タグ",
    "发布内容":"アイデア",
    "全部小组":"すべてのグループ",
    "投稿确认":"投稿の確認",
    "这里是全员参加的总部小组，请确认是否发布？":"このグループはあなたが所属している組織の全員が参加していますが、アイデアを投稿しますか？",
    "聊天室":"チャットルーム",
    "开启":"オンにする",
    "已开启":"有効化",
    "创建讨论组":"グループの作成"
}
let keywords = {
   "关键词":"キーワード",
   "今天的文章数":"今日の記事数",
   "文章总数":"合計の記事数",
   "企业内人气内容":"話題のアイデア",
   "人气News":"話題のニュースbot",
   "确定删除关键字？":"削除してよろしいですか？",
   "添加关键词":"キーワードを追加する",
   "订制小组成员感兴趣的主题，添加主题关键字，追踪相关情报资讯。":"キーワードを設定し、最新情報をゲットしよう！",
   "去添加":"設定する",
   "How to use":"詳細",
   "关键词.添加":"追加"
}
let dashboard = {
    "您有未发布内容，跳转其他小组将不会保留，确定跳转？": "編集中の投稿があります。他のグループへ移動する場合、編集中のデータは保存されませんが、移動しますか？",
    "我的小组": "マイグループ",
    "推荐加入": "参加のおすすめ",
    "小组数据": "グループ情報",
    "统计": "総計",
    "总用户数": "総メンバー数",
    "累计内容": "総投稿数",
    "累计阅读": "総閲覧数",
    "历史趋势": "過去の統計",
    "新增文章": "新規投稿",
    "新增评论": "新規コメント",
    "新增阅读": "新規閲覧",
    "新增用户": "新規アカウント",

    "您的试用版本还有{day}天即将到期，":"無料期間があと{day}日で終了します,",
    "请提前购买。":"事前に購入をお願いします。",
    "如有问题请联系开发人员。":"問題がある場合はお問合せください。",
    

    "小组数据": "アクティビティ",
    "Badge排名": "ランキング",

    "讨论组": "ディスカッショングループ",
    "订阅组": "掲示板グループ",
    "小组成员讨论空间，可用于信息交流、学习共享": "メンバー同士で情報交換可能",
    "企业专属信息发布空间，可用于拓展品牌影响": "管理者からの情報発信のみ可能",
    "小组名": "グループ名",
    "开放权限": "公開設定",
    "设置": "設定",
    "更改": "変更",
    "转让小组": "グループの権限を移す",
    "转让": "権限を移す",
    "企业总部暂不支持转让功能": "組織の管理人はグループの権限を移すことはできません",
    "解散小组": "グループを解散する",
    "企业总部暂不支持解散功能": "組織の管理人はグループを解散させることはできません",
    "修改小组信息": "グループの情報を変更する",
    "邀请成功": "招待しました",
    "邀请失败": "招待できません",
    "已经加入组织":"参加済み",
    "邀请链接已复制": "招待リンクをコピーしました",
    "宽":"幅",
    "高":"高い",
    "采集": "ピック",
    "已采集": "ピック済",
    "采集板": "最新ピック",
    "count人采集": "{count}人ピック",
    "采集成功，可到企业HQ小组的采集板查看": "ピックしました",
    '订阅流':'ニュースbot'
}

let article = {
    "赞": "いいね！",
    "已点赞": "いいね！しました",
    "评论": "コメント",
    
    "浏览": "閲覧",
    "次": "件",
    "浏览count次": "{count} 回閲覧",
    "请输入你的想法":"アイデアをシェアする",
    "编辑": "編集",
    "编辑文章": "編集",
    "收藏": "キープ",
    "设为素材":"ボード用コンテンツ",
    "将该文章设为素材，方便使用白板时以素材的方式进行使用":"ボード用コンテンツとして保存されます",
    "输入标签，方便寻找素材时使用":"ハッシュタグを入れて適切なコンテンツをいち早く見つかる",
    "确认采集":"確定する",
    "内容已进入素材库":"ボードのマイコンテンツに保存しました",
    "取消收藏": "キープをやめる",
    "置顶": "ピン固定",
    "取消置顶": "ピンを外す",
    "举报": "通告",

    "收藏成功": "キープしました",
    "已取消收藏": "キープをやめました",

    "分享链接复制成功": "リンクをコピーしました",

    "写下你的评论...": "コメントする",
    "您不是这则信息发布者，确认删除？": "他のメンバーが投稿したアイデアですが、削除しますか？",
    
    "分享": "シェア",
    "文章不存在，可能已被删除": "投稿が存在していません。すでに削除された可能性があります",
    "无访问权限": "閲覧権限がありません",
    "非公开小组，内容不可见": "公開されていないグループであり、投稿を見ることができません",
    "请先去登录": "先にログインしてください",
    "不能为空": "テキストの入力が必要です",
    "暂无评论": "コメントはありません",
    "展开count条回复": "{count}件の返信を表示する",
    "收起回复": "表示を隠す",

    "选择举报理由": "通告の理由を選んでください",
    "举报详细说明（必填）": "通告理由詳細（必ず入力してください）",
    "恶意灌水，垃圾广告": "悪意ある広告",
    "侵权内容，骚扰用户": "著作権侵害のある投稿",
    "色情、政治等敏感消息": "不適切な投稿",
    "举报已收到，我们会进行审核处理": "通告を受け付けました。確認結果は別途ご連絡します。",

    "查看所有count条评论": "{count}件のコメントをすべて見る",

    "重新编辑": "再編集",
    "只有链接？加些描述吧": "コメントも合わせて投稿してください",

    "上传照片": "画像をアップロードする",
    "上传文件": "ファイルをアップロードする",
    "写文章": "テキスト作成",
    "一起写": "ドキュメント",
    "话题": "タグ",
    "#插入话题#": "# タグ #",
    "图片": "画像",
    "文件校验中...":"確認しています…",
    "已存在链接，不能添加附件": "リンクが既に存在するため，添付できません",
    "只准添加同一类型附件": "同一種類の添付しかできません",
    "已上传附件，链接将不会自动解析": "アップロードしました。リンクは自動解析できません",
    "不支持添加文件夹": "このファイル形式はアップロードできません",

    "输入文字或网址...": "新しいアイデアを作成する",
    "释放上传": "アップデートがあります",

    "取消编辑": "編集取り消し",
    "您正在编辑内容，关闭将不会保留，确定关闭？": "このウィンドウを閉じると編集中のデータは保存されません。ウィンドウを閉じますか？",

    "每次发布只能上传一个文件": "１度に投稿できるのは１ファイルのみです",
    "最大100MB": "最大100MB",
    "最多上传count张图片": "最大で{count}件の画像をアップロードできます",
    "上传图片大小不能超过5MB": "アップロードする画像のサイズは5MBを超えることはできません",
    "count条评论":"{count}件のコメントと返信",
    "评论数count":"コメント {count}",
    "发布了一篇文章":"新しい投稿がありました",
    "您不是这篇文章最初的作者，无法删除这篇文章":"ドキュメントの最初の作成者だけが削除できます。",
    "您不是这篇文章的作者，无法删除这篇文章":"ドキュメントの作成者だけが削除できます。",
    "至少需要上传count张图":"至少需要上传{count}张图"
}

let profile = {
    "个人资料已更新":"プロファイルが更新されました",
    "头像": "プロフィール画像",
    "修改头像": "プロフィール画像を変更する",
    "昵称": "ニックネーム",
    "保存更改": "変更を保存する",
    "上传头像大小不能超过5MB": "アップロードする画像のサイズは5MBを超えることはできません",
    "上传中": "アップロード中",
    "头像修改成功": "プロフィール画像を変更しました",
    "昵称不能超过20个字符": "ニックネームは20文字を超えることはできません",
    "昵称修改成功": "ニックネームを変更しました",

    "修改密码": "パスワードを変更する",
    "邮箱": "メールアドレス",
    "密码": "パスワード",
    "旧密码": "現在のパスワード",
    "请输入旧密码": "現在のパスワードを入力してください",
    "两次密码不能一致": "前回と同じパスワードは使用できません",
    "密码修改成功": "パスワードを変更しました",
    "我评论的": "コメント",
    "编辑封面":"背景の編集",
    "我创建的小组":"作成したグループ",
    "创建的小组":"作成したグループ",
    "他创建的小组":"作成したグループ",
    "参加的圈子":"参加グループ"
}

let organization = {
    "付费及订单": "バージョン情報",
    "组织成员": "組織メンバー",
    "归档小组": "グループのアーカイブ",
    "归档": "アーカイブ",
    "组织管理员": "管理人",
    "管理员": "管理人",
    "成员": "メンバー",
    "已停用": "無効",
    "邀请成员到组织": "組織に招待する",
    "通过链接快速邀请成员加入": "リンクからメンバーに参加してください",
    "已付费名额已满，请前往续费": "メンバー数の上限に達しました。追加料金を支払ってください。",
    "操作失败": "失敗しました",
    "我已了解": "了解しました",
    "升级企业版": "企業版にアップデートする",
    "企业免费版": "無料版",
    "企业版": "企業版",
    "成员数量": "メンバー数",
    "有效期至": "利用期限",
    "当前版本": "バージョン",
    "退出企业":"組織から退出する"
}

let editor = {
    "草稿已保存": "保存しました",
    "正在保存...": "保存中...",
    "正在连接...": "リンク中...",
    "连接异常": "リンクエラー",
    "草稿箱": "テキストファイル",

    "粗体": "太字",
    "斜体": "斜体",
    "下划线": "下線",
    "中划线": "取り消し線",
    "标题1": "フォントサイズ特大",
    "标题2": "フォントサイズ大",
    "标题3": "フォントサイズ中",
    "有序列表": "段落番号",
    "无序列表": "箇条書き",
    "增加缩进": "インデント",
    "减少缩进": "インデント解除",
    "对齐方式": "文字揃え",
    "字体颜色": "文字の色",
    "背景颜色": "マーカー",
    "引用": "引用",
    "代码块": "コード書式",
    "超链接": "ハイパーリンク",
    "插入图片": "画像の挿入",

    "请输入标题": "タイトルを入力する",
    "确认文章信息": "テキストファイルの確認",
    "发布小组": "このグループに投稿する",
    "文章标题": "標題",
    "[文章]": "[テキスト]",
    "选择小组：": "グループを選択する：",
    "确认发布": "投稿",
    "选择小组": "グループを選択する",
    "我的草稿": "マイファイル",
    "新建": "新規作成",
    "无标题": "無題",
    "最后修改：": "最後の編集：",

    "修改标题失败，请稍后再试...": "標題の変更に失敗しました。しばらく経ってからもう一度行ってください...",
    "确认要删除该草稿吗？": "このファイルを削除してもよろしいですか",
    "删除提示": "確認",
    "草稿删除失败，请稍后再试...": "ファイルの削除に失敗しました。しばらく経ってからもう一度行ってください...",
    "文章标题不能为空": "標題を空白にすることはできません",
    "文章内容不能为空": "本文を空白にすることはできません",
    "发布文章失败，请稍后再试...": "投稿に失敗しました。しばらく経ってからもう一度行ってください...",
    "内容保护":"セキュリティ設定",
    "商业秘密":"商業秘密",
    "机密信息":"機密情報",
    "请输入文章":"本文を入力して下さい"
}

let interfaceErrorText = {
    "未知错误": "エラー",
    "服务端异常": "エラー",
    "客户端错误": "エラー",
    "非法数据": "違法データ",
    "解析错误": "解析エラー",
    "签名错误": "サインエラー",
    "参数错误": "パラメータエラー",
    "参数不能为空": "パラメータを空白にすることはできません",
    "记录未找到": "ファイルが見つかりませんでした",
    "请先注册": "アカウント登録してください",
    "密码错误": "パスワードが違います",
    "用户不存在": "このアカウントは存在していません",
    "邮箱类型不正确": "メールアドレスの形式が間違っています",
    "权限不足": "権限がありません",
    "成员已存在": "すでにHAROに参加しております",
    "邮件发送成功": "メールを送信しました",
    "邀请已发送，请勿频繁操作!": "招待を送りました。操作しないでください!",
    "该用户已注册": "このアカウントは登録済みです",
    "成员不存在": "メンバーが存在しません",
    "创建小组超过上限": "作れことができるグループ数の上限に達しました",
    "请验证邮箱": "認証メールを確認してください",
    "该手机号已绑定": "この番号はすでにリンクされています",
    "该用户为活跃用户": "このアカウントはログイン中です",

    "账户已被冻结": "アカウントが凍結されました",
    "您已举报过此信息": "通告しました",
    "仅管理员可发布!": "管理者のみが投稿できます",
    "请先登录!": "ログインしてください!",
    "访问过于频繁": "頻繁に閲覧しています",
    "发送信息失败，请稍后重试": "メッセージの投稿に失敗しました。しばらく経ってからもう一度行ってください",
    "已超过有效期!": "利用期限を過ぎています",
    "小组不存在": "グループが存在しません",
    "小组已存在": "グループがすでに存在しています",
    "该小组仅能转让给企业用户": "このグループは組織アカウントにのみ移管できます",
    "请先加入小组": "グループに参加してください"
}

let product = {
    "Miks是领先的“区块链+”企业创新协作平台，基于独创的DTCP协议为企业解决优质信息共享、筛选与沉淀等问题。通过闪电DNA技术、多服务器架构，在企业内让信息架构扁平化。": "Miks是领先的“区块链+”企业创新协作平台，基于独创的DTCP协议为企业解决优质信息共享、筛选与沉淀等问题。通过闪电DNA技术、多服务器架构，在企业内让信息架构扁平化。",

    "知识圈层": "知识圈层",
    "小组作为信息的组织单位，方便浏览与管理，轻松创建团队的知识库。": "小组作为信息的组织单位，方便浏览与管理，轻松创建团队的知识库。",
    "火眼推送": "火眼推送",
    "行业热点全连接，精选全网最专业信息源，实时认知升级。": "行业热点全连接，精选全网最专业信息源，实时认知升级。",
    "闪电DNA": "闪电DNA",
    "区块链专利技术保障企业数据安全，企业知识永久上链不丢失。": "区块链专利技术保障企业数据安全，企业知识永久上链不丢失。",

    "优质信息协同筛选，挖掘企业集体智慧": "优质信息协同筛选，挖掘企业集体智慧",
    "挖掘企业隐藏知识，制造更多智慧涌现，全局呈现组织内部的知识全景。": "挖掘企业隐藏知识，制造更多智慧涌现，全局呈现组织内部的知识全景。",

    "知识跨组织学习，注入“天生创新”的组织基因": "知识跨组织学习，注入“天生创新”的组织基因",
    "走出团队“智慧茧房”，前沿干货下沉至圈层，融入时代智慧浪潮。": "走出团队“智慧茧房”，前沿干货下沉至圈层，融入时代智慧浪潮。",

    "多端协作，高效分享，不错过任何一个优质信息": "多端协作，高效分享，不错过任何一个优质信息",
    "改善企业内部知识流动不畅": "改善企业内部知识流动不畅",
    "有效保存灵感、创意，沉淀为企业知识": "有效保存灵感、创意，沉淀为企业知识",
    "实现企业个体进化成长": "实现企业个体进化成长",

    "合作案例": "合作案例",
    "在Miks上聚合全球顶级商业资讯，深度报道协作采编、设计一站式服务，为全球服务器输出定制化的行业知识。": "在Miks上聚合全球顶级商业资讯，深度报道协作采编、设计一站式服务，为全球服务器输出定制化的行业知识。",
    "集团创新部门与数千家供应商在Miks上通力协作，为IOT及智慧城市业务的创新激发灵感。": "集团创新部门与数千家供应商在Miks上通力协作，为IOT及智慧城市业务的创新激发灵感。",
    "通过Miks打通组织内部信息屏障，使得团队内部认知代沟的同步速度翻倍，在高速奔跑的行业认知赛道上让每个成员都能成长。": "通过Miks打通组织内部信息屏障，使得团队内部认知代沟的同步速度翻倍，在高速奔跑的行业认知赛道上让每个成员都能成长。",
    "将社区生态架构于Miks之上，为数万社区用户提供多样化的信息服务，社区IP影响力的触达率提升70%。": "将社区生态架构于Miks之上，为数万社区用户提供多样化的信息服务，社区IP影响力的触达率提升70%。",

    "节点计划": "节点计划",
    "Miks支持节点搭建，任何组织和个人抵押数字资产即可成为节点生态一员": "Miks支持节点搭建，任何组织和个人抵押数字资产即可成为节点生态一员",
    "可选底层设施": "可选底层设施"
}

let price = {
    "选择适合你的版本": "选择适合你的版本",
    "Miks是一个分布式知识共享协作平台，基于独创的DTCP协议为企业解决优质信息共享、筛选与沉淀等问题。通过闪电DNA技术、多服务器架构，在企业内让信息架构扁平化。": "Miks是一个分布式知识共享协作平台，基于独创的DTCP协议为企业解决优质信息共享、筛选与沉淀等问题。通过闪电DNA技术、多服务器架构，在企业内让信息架构扁平化。",

    "创新版": "创新版",
    "基础版": "基础版",
    "适用于10人以下团队": "适用于10人以下团队",
    "可创建3个私密讨论组": "可创建3个私密讨论组",
    "每小组最多可加入10人": "每小组最多可加入10人",
    "2GB专属云空间": "2GB专属云空间",
    "不限加入/订阅其他小组": "不限加入/订阅其他小组",
    "信息区块链永久存证": "信息区块链永久存证",

    "企业/组织/团队版": "企业/组织/团队版",
    "适用于10人以上团队": "适用于10人以上团队",
    "立即升级": "立即升级",
    "基础版本所有功能": "基础版本所有功能",
    "新增团队总部小组": "新增团队总部小组",
    "可创建10个讨论组": "可创建10个讨论组",
    "每小组最多可加入500人": "每小组最多可加入500人",
    "可创建2个服务器公开订阅组": "可创建2个服务器公开订阅组",
    "200GB专属云空间": "200GB专属云空间",

    "试用到期": "试用到期",
    "试用期已到，可登录Miks网页端进行续费，你发布的所有内容都不受影响": "试用期已到，可登录Miks网页端进行续费，你发布的所有内容都不受影响",
    "直接升级": "直接升级",
    "了解详情": "了解详情"
}

let blankboard = {
   "未命名" : "新規ボード",
   "白板"   :"ボード",
   "推荐素材" : "グループのアイデア",
   "全部"   :"すべて",
   "文章"   :"テキスト",
   "短文"   :"投稿",
   "图片"   :"画像",
   "我的素材"  : "マイコンテンツ",
   "移除"   :"削除",
   "编辑"   :"編集",
   "blankboard.暂无数据":"暂无数据",
   "取消选择" :"選択を取り消す",
   "badge":"バッジ",
   "评论顺":"コメント順",
   "浏览顺":"閲覧顺",
   "请勿重复采集!":"このコンテンツはすでに選択されています!",
   "该白板只限单人编辑，该操作会向正在编辑者发出申请，需要同意后才能进行编辑":"現在の編集者に操作の許可を申請しますか？",
   "发出":"はい",
   "编辑中":"操作中",
   "开始编辑":"編集を開始する",
   "正在编辑":"が編集しています",
   "申请编辑":"編集する",
   "申请编辑白板":"が編集権限を申請しています",
   "拒绝":"拒否",
   "同意":"許可",
   "置顶层":"前面に移動",
   "置底层":"背面に移動",
   "暂无人编辑":"編集する人がいません",
   "在空白处双击添加便利贴":"ダブルクリックでテキストボックスを作成します",
   '确认是否删除白板"name"?':'ボード「{name}」を削除してよろしいですか?',
   '确认是否删除"name"?':'「{name}」を削除してよろしいですか?',
   '下载':'ダウンロード',
   '开始日期':'年/月/日',
   '结束日期':'年/月/日',
   '复制':'コピー',
   '素材库':'アイデア',
   '输入搜素的关键词':'アイデアを検索する',
   '日期':'日付',
   '是否退出白板':'ホワイトボードを終了するかどうか',
   '未处于编辑状态，请点击开始编辑':'編集状態ではありませんので、クリックして編集を開始してください'
}
export default {
    ...common,
    ...header,
    ...guideTips,
    ...search,
    ...notification,
    ...loginRegister,
    ...formVerify,
    ...group,
    ...dashboard,
    ...article,
    ...profile,
    ...organization,
    ...editor,
    ...interfaceErrorText,
    ...product,
    ...price,
    ...blankboard,
    ...keywords,
    "xxx": "xxx",  //to be translated
}