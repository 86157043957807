const resourcePath='@/store/modules/topic.js';const state = {
   topicData:{
      topics:[],
      isTopic:null,
      topics_style:null,
      insertCallback:null,
      topic_range:null
   }
};
const getters = {
};
const actions = {}
const mutations = {
   updateTopics(state,topics) {
       state.topicData.topics = topics
   },
   updateTopicStyle(state,topics_style) {
      state.topicData.topics_style = topics_style;
   },
   updateTopicRange(state,topic_range) {
      state.topicData.topic_range = topic_range;
   },
   updateTopicCallback(state,insertCallback) {
      state.topicData.insertCallback = insertCallback;
   },
   updateIsTopic(state,isTopic) {
      state.topicData.isTopic = isTopic;
   }
};

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
};
