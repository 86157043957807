const resourcePath='@/filters/domain2node.js';export default (domain, nodes) => {
    // let node_name = '点到'

    let dev_node;
    
    if (/^http:.*?:1115$/i.test(domain)) {
        // 开发环境默认点到
        for (let index = 0; index < nodes.length; index++) {
            if (nodes[index].node_address == '0x5bb297a46512233e9f52f74e0cafd6ecb2d2db07') {
                dev_node = nodes[index]
                return dev_node
            }
        }
        // return nodes[0]
    }

    let cur_node = nodes.find((node) => {
        return node.web === domain.replace(/www\./i, '')
    })

    // console.log(cur_node)
    // console.log(nodes[0])

    return cur_node || nodes[0]
    // return cur_node || dev_node
}