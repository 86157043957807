const resourcePath='@/filters/text2html.js';import * as util from '@/common/function/util';
import text2highlight from './text2highlight'

export default (text, mentions, highlight, topics) => {
    if (!text) return;
    // text = text.replace(/[\r\n]/g, '<br>'); //去掉回车换行
    // text = text.replace(/[\n]/g, '<br>'); //去掉换行
    // text = text.replace(/[\r]/g, '<br>'); //去掉回车


    //多个换行转一个
    // text = text.replace(/(<br>){2,}/ig, '<br><br>');
    // text = text.replace(/(\n\r)/ig, '123');
    // text = text.replace(/(\r\n)/ig, '<br>');
    // text = text.replace(/(\r)/ig, '<br>');
    text = text.replace(/(\n)/ig, '<br>');
    // text = text.replace(/(123123)/ig, '456');

    // text = text.replace(/(<br>){2,}/ig, '<br><br>');

    //换行符转br
    // text = text.replace(/[\n]/ig, '<br>');
    // text = text.replace(/[\n\r]/ig, '<br>');


    
    //首尾空白字符 删除
    text = text.replace(/^(<br>)+|(<br>)+$/ig, '');
    //去除原数据A标签
    let reg_link = /<a.*?>([^\n\r\s<>]+?)<\/a>/ig;
    text = text.replace(reg_link, function (match, p1, p2, p3) {
        return p1
    })
    // http链接直接转成a标签
    let reg_http = /http(s)?:\/\/[^\n\r\s<>]+/ig;
    text = text.replace(reg_http, function (match, p1, p2, p3) {
        return `<a class="is-append-link" target="_blank" href="${match}">${match}</a>`
    })
    // @转链接
    if(mentions && mentions.length) {
      let mentions_arr = util.deepcopy(mentions);
      for(let i = 0; i < mentions_arr.length; i ++) {
         let mention = mentions_arr[i] 
         let reg = new RegExp(mention.name);
         text = text.replace(reg,function (match, p1, p2, p3){
            return  `<a class="is-append-link" target="_blank" href="/author/${mention.out_uid}">${match}</a>`;
         });  
      }
    }
   //  if (mentions && mentions.length) {
   //      let mentions_arr = util.deepcopy(mentions);
   //      let reg_mention = /@[^\n\r@<>]+/ig;
   //      text = text.replace(reg_mention, function (match, p1, p2, p3) {
   //          // console.log(match)

   //          let idx = mentions_arr.findIndex((item) => {
   //              return item.name === match
   //          })
   //          // console.log(mentions_arr,idx)
   //          if (idx !== -1) {
   //              let temp = `<a class="is-append-link" target="_blank" href="/author/${mentions_arr[idx].out_uid}">${match}</a>`
   //              mentions_arr.splice(idx, 1)
   //              return temp
   //          } else {
   //              return match
   //          }
   //      })
   //  }
    //highlight 转高亮
    text=text2highlight(text,highlight)

    // 解析话题
    if (topics && topics.length) {
        let topics_arr = util.deepcopy(topics).map(item => {
            return '#' + item + '#'
        });
        let reg_topic = /#[^#]+#/g;

        text = text.replace(reg_topic, function (match) {
            let idx = topics_arr.findIndex((item) => {
                return item === match
            })
            if (idx !== -1) {
                // let temp = `<a class="is-topic-link" target="_blank" href="/topic?wd=${match.replace(/#/g, '')}">${match}</a>`
                let temp = `<a class="is-topic-link" target="_blank" href="/search/${match.replace(/#/g, '%23')}?type=topic">${match}</a>`
                topics_arr.splice(idx, 1)
                return temp
            } else {
                return match
            }
        })
        
    }

    return text
}