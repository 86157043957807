const resourcePath='@/components/repost-modal/index.vue';
import { createDynamicEntrance } from "@/common/function/dynamicUtil.js";
import defaultRepost from './exports/repost-modal.vue'
export default createDynamicEntrance(
   "main.modal.repost-modal",
   defaultRepost,
   {
      group: {
          // required: true,
          type: Object,    //需要修改 props传过来的值
          default: null
      },
      is_keyword:{
         type: Boolean,    //需要修改 props传过来的值
          default: false
      },
      articleDraft: {
          type: Object,
          required: true
      },
      visible: {
          type: Boolean,
          default: false
      }
   },
   null
);
