const resourcePath='@/service/topic-hint.js';
import store from '@/store'
import TopicAPI from '@/api/v3/topic'
export default class TopicHintService{
    constructor(attachedCmp){
        this.component = attachedCmp
    }
    showTopicHintOnCurSelection(group_id,pref,callback){
        let sel = window.getSelection();
        let range = sel.getRangeAt(0);
        let boundingRect = range.getBoundingClientRect();
        let topics_style = {
           left: boundingRect.x - 8  + "px",
           top: boundingRect.y +
              boundingRect.height +
              "px",
        };
         TopicAPI.getGroupTopics(group_id,0,999,pref).then((res)=>{
            let topics = [{topic:pref,heat:0}]
            if(res && res.length > 0 && pref && pref != '') {
               topics = topics.concat(res.filter((itm)=>itm.topic.indexOf(pref) == 0))
            } else {
               topics = topics.concat(res)
            }
            store.commit('topic/updateTopicStyle',topics_style)
            store.commit('topic/updateTopics',topics)
            store.commit('topic/updateTopicCallback',callback)
            store.commit('topic/updateTopicRange',range);
         })
    }
    hideTopicHint(){
        store.commit('topic/updateTopicStyle',null)
    }
}