const resourcePath='@/api/v3/disclose.js';import {httpClient} from './v3';

const DisclosureAPI = {
    CreateLink: (data) => {
        return httpClient.post('/disclosure', data);
    },
    MyDisclosedFiles:(configParams)=>{
        return httpClient.get(`/disclosure`,configParams);
    },
    RetreiveLinkInfo:(Id) =>{
      return httpClient.get(`/disclosure/${Id}`);
    },
    RequestVerification:(linkId,phone) => {
      return httpClient.post(`/sms`,{phone_number:phone,a_type:0,rec_id:linkId});
    },
    CheckVerification:(linkId,vCode)=>{
      return httpClient.get(`/disclosure/${linkId}/verify`,{params:{verification_code:vCode}});
    },
    signAndProceed:(linkId,data)=>{
      return httpClient.post(`/disclosure/${linkId}/sign`,data);
    }
    
}
export default DisclosureAPI;