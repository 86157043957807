const resourcePath='@/views/dashboard/dialogs/create-group-dialog.vue';//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import API_group from '@/api/group';
import API_upload from '@/api/upload';
import txtFilter from '@/common/function/txt-filter'
export default {
   props: {
      visible: {
         type: Boolean,
         default: false,
      },
      blog_group_count:{
         type:Number,
         default:0
      },
      discuss_group_count:{
         type:Number,
         default:0
      }
   },
   computed: {
      ...mapState(["user", "isPhone", "nodes", "locale"]),
      can_create_blog_group() {
         return this.blog_group_count < this.max_blog_group_count;
      },
      can_create_discuss_group() {
         return this.discuss_group_count < this.max_discuss_group_count;
      },
   },
   data() {
      return {
         stepTitle: this.$t("选择小组类型"),
         upload_percent: "",
         upload_face_id: "",
         upload_succ_url: "",
         action_url: this.$axios.defaults.baseURL + API_upload.image,
         createFlag: true,
         createGroupParams: {
            name: "",
            group_desc: "",
            company_type: "",
            head_img_id: "",
            public_type: 3, //public_type： 1.私密  3.企业内公开  4.公开
         },
         groupType: "",
         max_blog_group_count: 2,
         max_discuss_group_count: 10,
         loading:false
      };
   },
   mounted() {},
   methods: {
      handleProgress(event, file, fileList) {
                let per = parseInt(event.percent);
                // console.log(per);

                if (per > 10) {
                    this.upload_percent = `${this.$t('上传中')}(${per - 1}%)`
                }

            },
      beforeUpload(file) {
                let isLt5M = file.size / 1024 / 1024 < 5;
                if (!isLt5M) {
                    this.$message({
                        message: this.$t('上传头像大小不能超过5MB'),
                        type: 'warning'
                    });
                    return false;
                }
            },
      createClose() {
         this.createGroupParams = {
            name: "",
            group_desc: "", // 待优化：填充的文本由于 v-if 自动清空了
            public_type: 3,
         };
         this.groupType = "";
         this.upload_face_id = "";
         this.upload_succ_url = "";
         this.$emit('close')
      },
      handleSuccess(res, file, fileList) {
                // this.upload_succ_url = res.data[0].image_url + '?x-oss-process=image/resize,m_fill,w_260,h_260,limit_0';
                this.createGroupParams.head_img_id = res.data[0].image_id;

                setTimeout(() => {
                    this.upload_succ_url = res.data[0].image_url + '?x-oss-process=image/resize,m_fill,w_260,h_260,limit_0';

                }, 200)
                
                let img = new Image();
                let vm = this;
                img.onload = function () {
                    // vm.user.head_img_id = res.data[0].image_id
                    // vm.upload_succ_url = upload_succ_url
                    // vm.upload_percent = ''
                    // vm.update_face()
                };
                // img.src = upload_succ_url

                vm.upload_percent = ''

            },
      selectDiscuss() {
         if (!this.can_create_discuss_group) return;
         this.groupType = 1;
         this.stepTitle = this.$t("创建讨论组");
         if (this.user.in_type == 2) {
            this.createGroupParams.public_type = 1;
         }
      },
      selectSubscribe() {
         if (!this.can_create_blog_group) return;
         if (this.user.in_type == 2) {
            this.$message({
               message: "订阅组功能仅对企业用户开放",
               type: "warning",
            });
         } else {
            this.groupType = 9;
            this.stepTitle = this.$t("创建订阅组");
         }
      },
      upload_group_face() {
         $("#face-upload").trigger("click");
      },
      handle_paste() {
         event.preventDefault();
         let paste = event.clipboardData.getData("text");
         document.execCommand("insertText", false, paste + "");
         let sel = window.getSelection();
         sel.selectAllChildren($("#groupDesc")[0]);
         sel.collapseToEnd();
      },
      handle_input() {
         if ($("#groupDesc").text()) {
            $("#groupDesc").removeClass("empty");
         } else {
            $("#groupDesc").addClass("empty");
         }
      },
      createBackstep() {
         this.groupType = "";
         this.stepTitle = this.$t("选择小组类型");
         this.createGroupParams.public_type = 3;
      },
      async createPost() {
         if (!this.createFlag) return;

         if (!this.createGroupParams.head_img_id) {
            this.$message({
               message: this.$t("请上传小组头像"),
               type: "error",
            });
            return;
         }

         if (!this.createGroupParams.name) {
            this.$message({
               message: this.$t("请输入小组名称"),
               type: "error",
            });
            return;
         }

         // if (this.createGroupParams.name.length > 12) {
         //     this.$message({
         //         message: this.$t('小组名称不能超过12个字符'),
         //         type: 'error'
         //     });
         //     return;
         // }

         this.createGroupParams.company_type = this.groupType;

         if ($("#groupDesc").html().substring(0, 3) == "<br") {
            $("#groupDesc").html("");
         }
         this.createGroupParams.group_desc = txtFilter($("#groupDesc").html());

         this.createFlag = false; // 已修复：阻止连续点击创建多个

         let res;
         // try {
         //     res = await this.$axios.post(API_group.common_url, this.createGroupParams)
         // } catch (error) {
         //     console.log(error)
         // }
         // console.log(res)
         this.loading = true
         await this.$axios
            .post(API_group.common_url, this.createGroupParams)
            .then((resp) => {
               res = resp;
            })
            .catch((error) => {
               console.log(error.response);
            });

         this.createFlag = true;

         // 待优化：创建完成后的动画，没有交互体验（还需要考虑创建失败：创建小组超过上限）
         this.createDialogVisible = false;

         // 待优化：后台应该在我创建小组成功，返回给我小组的信息，还是我拿到 小组id 再去请求小组信息
         if (res) {
            await this.$axios
               .get(`${API_group.common_url}/${res.data.data.group_id}`)
               .then((resdata) => {
                  // this.createFlag = true;     // 应该放在上面

                  return this.$store
                     .dispatch("update_myGroups", {
                        page: 0,
                        page_size: 999,
                        source: 1,
                     })
                     .then((result) => {
                        this.$router.push({
                           name: "group",
                           params: { id: resdata.data.data.group_id },
                        });
                     });
               })
               .catch(() => {})
               .finally(()=>{
                  this.loading = false
               });
         }
         this.createClose();
         this.loading = false
         // this.createDialogVisible = false;
         // this.createClose();
      },
   },
};
