const resourcePath='@/common/function/i18n.js';import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import zh from '../../assets/lang/zh.js'
import jp from '../../assets/lang/jp.js'

import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import jpLocale from 'element-ui/lib/locale/lang/ja'
import ElementLocale from 'element-ui/lib/locale'

var language = (navigator.browserLanguage || navigator.language).toLowerCase();
var localeLang = '';
if (language.indexOf('ja') > -1) {
    localeLang = 'jp'
} else {
    localeLang = 'zh'
}


const i18n = new VueI18n({
    locale: localeLang,    // 语言标识
    // locale: 'zh',    // 语言标识
    fallbackLocale: 'zh',
    messages: {
        zh: Object.assign(zh, zhLocale),
        jp: Object.assign(jp, jpLocale)
    }
})

ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n;