const resourcePath='@/views/dashboard/dialogs/sms-agreement-dialog.vue';//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DisclosureAPI from '@/api/v3/disclose.js'
export default {
   props: {
      linkId:{ 
         type:String,
         required:true
      },
      phone:{
         type:String,
         required:true
      }
   },
   data(){
      return {
         step:0,
         fields:{
            receiver:'',
            receiverPhone:''
         },
         promise_confidential:false,
         promise_no_disclosure:false,
         promise_no_copy:false,
         formRule:{
            receiver:[{required:true,trigger:'all',message:'请输入姓名'}],
            receiverPhone:[{required:true,trigger:'all',message:'请输入电话'}]
         }
      }
   },
   computed: {
   },
   methods: {
      refuse(){
         this.$store.commit('modal_dialogs/closeDialog');
      },
      proceed(){
         DisclosureAPI.signAndProceed(this.linkId,{phone_number:this.phone}).then((resp)=>{
            let url = resp.url
            if(/https:/.test(window.location.protocol)) {
               url = url.replace(/http:/,'https:')
            }
            window.open(url,"_self","")
         })
      }
   },
   mounted() {
   },
};
