const resourcePath='@/store/modules/dynamiccmp.js';const state = {
   registed:{},
   registedCSS:{}
};

const getters = {
   
};

const actions = {
};


const mutations = {
   registDynamicCmp (state,cmps) {
      Object.keys(cmps).forEach((key)=>{
         let info = cmps[key]
         if(!state.registed[key]) {
            state.registed[key] = info.cmp
         } else {
            let oldPath = state.registed[key].path,
                nPath = info.path
            if(nPath.split('/').length > oldPath.split('/').length) {
               state.registed[key] = info.cmp
            }
         }
      })
   },
   registDynamicCss (state,css) {
      Object.keys(css).forEach((key)=>{
         let info = css[key]
         if(!state.registedCSS[key]) {
            state.registedCSS[key] = info.css
         } else {
            let oldPath = state.registedCSS[key].path,
                nPath = info.path
            if(nPath.split('/').length > oldPath.split('/').length) {
               state.registedCSS[key] = info.css
            }
         }
      })
   }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
