const resourcePath='@/store/modules/collaborative.js';import CollaborativeAPI from '@/api/v4/collaborative';
import Article_API from '@/api/v2/article.js'
const state = {
    collaborativeDocs: {
        list: [],
        pageParams: {
            page: 0,
            page_size: 20
        },
        group_id:null
    },
    deletedCollaborativeDocs:[]
};

const getters = {
    
};

const actions = {
    createCollaborativeDoc(context, groupId) {
        return new Promise((resolve, reject) => {
            CollaborativeAPI.CreateCollaborativeDoc(groupId)
                .then((collaborativeDoc) => {
                    resolve(collaborativeDoc)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getCollaborativeDocs(context, pageParams = {page: 0, page_size: 20}) {

        return new Promise((resolve, reject) => {
         Article_API.getArticles(pageParams).then((collaborativeDocs)=>{
            let list = collaborativeDocs || [];
            pageParams.page++;
            pageParams.page_size = list.length > 20 ? 20 : list.length;
            context.commit("updateCollaborativeDocs", {list, pageParams});
            resolve(collaborativeDocs)
         }).catch((error) => {
                    reject(error)
          })
        })
    },
    deleteCollaborativeDoc(context, collaborativeId) {
        return new Promise((resolve, reject) => {
            CollaborativeAPI.DeleteCollaborativeDoc(collaborativeId)
                .then((res) => {
                    context.commit('deleteCollaborativeDoc', collaborativeId)
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getCollaborativeDoc(context, collaborativeId) {
        return new Promise((resolve, reject) => {
            CollaborativeAPI.GetCollaborativeDoc(collaborativeId)
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    deleteDraft(context, draftId) {
        return new Promise((resolve, reject) => {
            DraftAPI.DeleteDraft(draftId)
                .then(()=> {
                    context.commit("deleteDraft", draftId);
                    resolve();
                })
                .catch((err)=>{
                    reject(err);
                });
        });
    },

    updateDraftTitle(context, {draft, title, timestamp}) {

        let lastTitleEditTimestamp = timestamp;

        return new Promise((resolve, reject) => {

            let oldTitle = draft.draft_title;

            context.commit("updateDraftTitle", {draft, title});

            setTimeout(() => {
                if(lastTitleEditTimestamp === timestamp) {
                    DraftAPI.UpdateDraft(draft.draft_id, {draft_title: title})
                        .then((updatedDraft) => {
                            resolve(updatedDraft);
                        })
                        .catch((err) => {
                            context.commit("updateDraftTitle", {draft, oldTitle});
                            reject(err);
                        });
                } else {
                    resolve(draft);
                }
            }, 1000);
        });
    },
    publishDraft(context, {draftId, groupId, content}) {
        return new Promise((resolve, reject) => {
            DraftAPI.PublishDraft(draftId, groupId, content)
                .then((article) => {

                    context.commit("deleteDraft", draftId);

                    resolve(article);
                })
                .catch((err)=>{
                    reject(err);
                });
        });
    }
};

const mutations = {
    clearCollaborativeDocs(state,list) {
        state.collaborativeDocs.list = []
    },
    updateCollaborativeDocs(state, list) {
        state.collaborativeDocs.list.push(...list)
    },
    deleteCollaborativeDoc(state, article_Id) {
        state.collaborativeDocs.list = state.collaborativeDocs.list.filter((itm)=>
        itm.article_id != article_Id);

    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
