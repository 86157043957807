const resourcePath='@/api/article.js';import config from '@/config';

const common_url = config.server_version + '/articles';

const share_url = config.server_version + '/share';

const draft_url = config.server_version + '/draft';

export default {
    common_url,
    article(aid){
        return `${common_url}/${aid}`
    },
    comment(aid){
        return `${common_url}/${aid}/comments`
    },
    comment_single(aid,cid){
        return `${common_url}/${aid}/comments/${cid}`
    },
    share_url,
    share(aid) {
        return `${share_url}/${aid}`
    },
    share_comment(aid){
        return `${share_url}/${aid}/comments`
    },

    draft_url,
    draft(aid){
        return `${draft_url}/${aid}`
    },

    pick(articleId) {
        return `${common_url}/${articleId}/pick`
    }

}