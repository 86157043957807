const resourcePath='@/views/dashboard/components/mentions.vue';//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import scrollLoading from "@/components/scroll-loading.vue";
import face from "@/components/face.vue";
export default {
   name: "",
   components: {
      scrollLoading,
      face,
   },
   props: {
      mentions_style: {
         required: true,
      },
      range: {
         required: true,
      },
      group_id: {
         required: true,
      },
   },
   computed: {
      user() {
         return this.$store.state.user;
      },
      groupMemberList() {
         return this.$store.state.groupMember.list;
      },
      memberList() {
         return this.quickName(this.name);
      },
      groupMemberPageParams() {
         return this.$store.state.groupMember.pageParams;
      },
      calced_style() {
         let top = (this.mentions_style && this.mentions_style.top) || "0",
            topPx = top.replace(/px/g, "");
         if (topPx < window.innerHeight / 2) {
            return {
               ...this.mentions_style,
            };
         } else {
            return {
               ...this.mentions_style,
               top: `${topPx - 316}px`,
            };
         }
      },
      positionTop() {
         let top = (this.mentions_style && this.mentions_style.top) || "0",
            topPx = top.replace(/px/g, "");
         if (topPx < window.innerHeight / 2) {
            return true;
         } else {
            return false;
         }
      },
   },
   data() {
      return {
         // members: [],
         name: "",
         loading: false,
         activeIdx : 0
      };
   },
   methods: {
      fixScroll(){
         let selectedItem = this.$refs[`item-${this.activeIdx}`][0],
             scrollContainer = this.$refs['scroll-container'];
         if(selectedItem.getBoundingClientRect().y + selectedItem.getBoundingClientRect().height - 
               scrollContainer.getBoundingClientRect().y >  scrollContainer.getBoundingClientRect().height) {
               scrollContainer.scrollTop += selectedItem.getBoundingClientRect().y + selectedItem.getBoundingClientRect().height - 
               scrollContainer.getBoundingClientRect().y - scrollContainer.getBoundingClientRect().height
            } else if(selectedItem.getBoundingClientRect().y < scrollContainer.getBoundingClientRect().y){
               scrollContainer.scrollTop -=  scrollContainer.getBoundingClientRect().y - 
               selectedItem.getBoundingClientRect().y
            }
      },
      handleKeyUp(event){
         if(event.keyCode == 40) { // arrow down
            this.activeIdx = Math.min(this.memberList.length - 1,this.activeIdx + 1)
            this.fixScroll()
         } else if(event.keyCode == 38) {
            this.activeIdx = Math.max(0,this.activeIdx - 1)
            this.fixScroll()
         } else if(event.keyCode == 13) {
            this.select_member(this.memberList[this.activeIdx])
         }
      },
      select_member(member) {
         console.log(window.getSelection(), this.range);
         window.getSelection().removeAllRanges();
         window.getSelection().addRange(this.range);
         this.$store.commit("mention/updateMentionStyle", null);
         let temp = `<a class="is-append-link" target="_blank" href="/author/${member.out_uid}">@${member.name}</a>`;
         // let temp = `<a class="is-append-link" target="_blank" href="/author/${member.out_uid}">${member.name}</a>`;
         let temp_node = $(temp)[0];
         let space_node = document.createTextNode("\u00A0");
         let range = window.getSelection().getRangeAt(0);
         range.deleteContents();
         range.insertNode(space_node);
         range.insertNode(temp_node);
         window.getSelection().selectAllChildren(range.commonAncestorContainer); //range 选择obj下所有子内容
         window.getSelection().collapseToEnd(); //光标移至最后
         this.$emit("insertMentions", member);
      },
      load() {},
      // 小组成员列表:分页
      async groupMemberLoad() {
         if (this.loading) return;

         if (this.groupMemberPageParams.page_size < 20) return;

         this.loading = true;

         let params = {
            group_id: this.group_id,
            ...this.groupMemberPageParams,
         };

         await this.$store.dispatch("getGroupMember", params).then(() => {
            console.log("已get小组成员");
         });
         this.loading = false;
      },
      init(group_id) {
         if (this.user.out_uid) {
            this.load();
         }
      },
      quickName(str) {
         if (!str || str == "") {
            return this.groupMemberList.filter((itm)=>itm.out_uid != this.user.out_uid);
         } else {
            return this.groupMemberList.filter(
               (itm) => (itm.name.indexOf(str) == 0 && itm.out_uid != this.user.out_uid)
            );
         }
      },
   },
   mounted() {
      // console.log($('.mentions-panel').height())

      // 待优化：提前获取 mentions-panel 的高度，方便定位
      // document.querySelector('body').style.position = 'fixed'
      setTimeout(() => {
         // console.log($('.mentions-panel').height())
      }, 300);
      $("body").on("click.mentions", () => {
         // console.log('close')
         this.$store.commit("mention/updateMentionStyle", null);
      });
   },
   watch: {
      group_id: function (newVal, oldVal) {
         if (newVal) {
            this.init(newVal);
         }
      },
      mentions_style: function (newV, oldV) {
         if (newV && newV.display !== "none") {
            this.name = ''
            this.$nextTick(() => {
               if (this.$refs.inputTop) this.$refs.inputTop.select();
               else if (this.$refs.inputBottom) this.$refs.inputBottom.select();
            });
         }
      },
      memberList:function(nV,oV) {
         if(nV != oV) {
            this.activeIdx = 0
         }
      }
   },
   created() {
      this.init(this.group_id);
   },
   beforeDestroy() {
      $("body").off("click.mentions");
   },
};
