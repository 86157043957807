const resourcePath='@/components/appbanner/releaseTip.vue';//
//
//
//
//
//
//
//
//
//

import dayjs from "dayjs";
import { mapState } from "vuex";
export default {
   name: "",
   props: {
      buildTime: {
         type: Number,
         default: 0,
      },
   },
   computed: {
      displayedBuildTime() {
         return dayjs(new Date(this.buildTime * 1000)).format(
            "YYYY年MM月DD日 HH:mm"
         );
      },
      ...mapState(["locale", "outdated", "appVersionInfo"]),
      trigger() {
         return this.outdated;
      },
   },
   watch: {
      trigger: function (nV, oV) {
         if (nV) {
            this.$store.commit("appbanner/triggerBanner", {
               name: "release-tip",
            });
         } else {
            this.$store.commit("appbanner/removeBanner", {
               name: "release-tip",
            });
         }
      },
   },
   methods: {
      onRefresh() {
         window.location.reload();
      },
   },
};
