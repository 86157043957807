const resourcePath='@/assets/lang/zh.js';let common = {
    "协作交流": "协作交流",
    "共同成长": "共同成长",
    "协作交流 · 共同成长": "协作交流 · 共同成长",
    "暂无数据": "暂无数据",
    "暂无内容": "暂无内容",
    "删除": "删除",
    "确认删除？": "确认删除？",
    "提交": "提交",
    "发布": "发布",
    "确定": "确定",
    "取消": "取消",
    "转入":"转入",
    "我是有底线的": "我是有底线的",
    "复制链接": "复制链接",
    "回复": "回复",
    "评论.回复":"回复",
    "返回": "返回",
    "内容": "内容",
    "主页.内容":"内容",
    "num内容": "内容",
    "最近count投稿":"最近{count} 投稿",
    "count投稿":"{count} 投稿",
    "count内容": "{count} 内容",
    "count成员": "{count} 成员",
    "文件": "文件",
    "小组": "小组",
    "用户": "用户",
    "人": "人",
    "年": "年",
    "天": "天",
    "发送":"发送",
    "查看更多": "查看更多",
    "全部": "全部",
    "上一步": "上一步",
    "完成": "完成",
    "确认修改": "确认修改",
    "修改成功": "修改成功",
    "邀请": "邀请",
    "提示": "提示",
    "昨天": "昨天",
    "前天": "前天",
    "一周内": "一周内",
    "一个月内": "一个月内",
    "现在":"现在",
    "邀请你加入组织":"邀请你加入组织",
    "请输入您的邮箱":"请输入您的邮箱",
    "立即加入":"立即加入",
    "登录Miks和组织伙伴一起交流分享吧":"登录Miks和组织伙伴一起交流分享吧",
    "可前往下载Miks APP":"可前往下载Miks APP",
    "前往Miks网页版":"前往Miks网页版",
    "还差一步，完成验证后即可加入":"还差一步，完成验证后即可加入",
    "请登录":"请登录",
    "验证你的邮箱":"验证你的邮箱",
    "创建组织信息":"创建组织信息",
    "创建成功，邀请组织成员":"创建成功，邀请组织成员",
    "输入团队组织名称":"输入团队组织名称",
    "下一步":"下一步",
    "邀请并进入":"邀请并进入",
    "输入成员邮箱":"输入成员邮箱",
    "跳过，稍后邀请":"跳过，稍后邀请",
    "请输入组织名称":"请输入组织名称",
    "申请加入组织":"申请加入组织",
    "申请已提交":"申请已提交",
    "申请已成功发送给组织管理员，前往Miks确认是否已加⼊成功":"申请已成功发送给组织管理员，前往Miks确认是否已加⼊成功",
    "链接已失效":"链接已失效",
    "链接已失效，你可以联系邀请你的⼈，以获取新链接":"链接已失效，你可以联系邀请你的⼈，以获取新链接",
    "组织成员已满，不可加入":"组织成员已满，不可加入",
    "申请加入失败":"申请加入失败",
    "你当前已加⼊组织\"name\"，如需加⼊新组织，请先解除当前企业成员身份":"你当前已加⼊组织“{name}”，如需加⼊新组织，请先解除当前企业成员身份",
    "当前企业付费名额已满":"当前企业付费名额已满",
    "申请失败":"申请失败",
    "@和评论":"@和评论",
    "系统已在东京时间time完成版本迭代，如若无法正常使用Miks，请刷新一下页面，或退出账户重新登录":"系统已在东京时间{time}完成版本迭代，如若无法正常使用Miks，请刷新一下页面，或退出账户重新登录"
}

let header = {
    "学习型组织的进化利器": "学习型组织的进化利器",
    "首页": "首页",
    "发现": "发现",
    "升级": "升级",
    "登录": "登录",
    "注册": "注册",
    "登录注册": "登录注册",

    "个人中心": "个人中心",
    "我的内容": "我的内容",
    "我的收藏": "我的收藏",
    "我的订单": "我的订单",
    "我的组织": "我的组织",
    "退出登录": "退出登录",
    
    "个人主页": "个人主页",
    "账号设置": "账号设置",
    "他创建的": "我创建的",

    "他的内容": "Ta的内容",

    "积分商城": "Miks小卖部",
    'Miks':'Miks'
}

let guideTips = {
    "发现公开小组": "发现公开小组",
    "创建团队小组": "创建团队小组"
}

let search = {
    "搜索": "搜索",
    "查看所有结果": "查看“{word}”所有结果",
    "搜索结果": "搜索结果",
    "综合": "综合",
    "没有找到匹配结果，你可以再试一次": "没有找到匹配结果，你可以再试一次",
    "企业内内容": "企业内内容",
    "企业外内容": "企业外内容",
    "组织内":"组织内",
    "范围":"范围",
    "发布人":"发布人",
    "不限":"不限"
}

let notification = {
    "互动": "互动",
    "通知": "通知",
    "标记全部已读": "标记全部已读",
    "查看全部消息": "查看全部消息",
    "评论已删除": "评论已删除",
    "我的消息": "我的消息",

    "评论了你的文章": "评论了你的文章",
    "评论中@了你": "评论中@了你",
    "@了你": "@了你",
    "回复了你的评论": "回复了你的评论",
    "赞了你的文章": "赞了你的文章",
    "赏了你的文章": "赏了你的文章",
    "赞了你的评论": "赞了你的评论",
    "评论了usercomment": "评论了 {user} {comment}",
    "@和评论":"@和评论",
    "已将group转让给你": "已将{group}转让给你",
    "你被user加入group": "你被{user}加入{group}",
    "加入了group": "加入了{group}",
    "邀请你加入group": "邀请你加入{group}",
    "新增了count篇内容": "新增了{count}篇内容",
    "你已成功加入组织organization": "你已成功加入组织{organization}",
    "已成功加入组织organization": "已成功加入组织{organization}",
	"已将你从group小组移出": "已将你从{group}小组移出",
    "删除了你的文章": "删除了你的文章",
    "已将group小组归档": "已将{group}小组归档",
    "@你":"@你",
    "提到":"提到",
    "收起":"收起",
    "展开":"展开",
    "其它":"其它",
    "已读":"已读"
}

let loginRegister = {
    "邮箱注册": "邮箱注册",
    "忘记密码": "忘记密码",
    "忘记密码？": "忘记密码？",
    "输入邮箱地址": "输入邮箱地址",
    "输入账号密码": "输入账号密码",
    "注册即表明阅读并同意": "注册即表明阅读并同意",
    "用户协议": "用户协议",
    "已有账号？去登录": "已有账号？去登录",

    "验证邮箱完成注册": "验证邮箱完成注册",
    "请检查电子邮件以完成注册。": "请检查电子邮件以完成注册。",
    "没有收到电子邮件？请尝试检查垃圾邮件文件夹": "没有收到电子邮件？请尝试检查垃圾邮件文件夹",

    "请查收您的收件箱": "请查收您的收件箱",
    "密码重置邮件已发送给": "密码重置邮件已发送给",
    "未收到邮件？": "未收到邮件？",
    "重新发送": "重新发送",
    "使用其他邮箱": "使用其他邮箱",
    "已向你发送电子邮件，请检查邮箱": "已向你发送电子邮件，请检查邮箱",

    "邮箱验证成功！": "邮箱验证成功！",
    "正在验证": "正在验证",
    "网页端": "网页端",
    "直接使用": "直接使用",

    "重置密码": "重置密码",
    "新密码": "新密码",
    "重复": "重复",
    "请输入新密码": "请输入新密码",
    "请再次输入新密码": "请再次输入新密码",
    "两次密码不一致": "两次密码不一致",
    "修改成功，重新登录": "修改成功，重新登录"
}

let formVerify = {
    "请填写完整表单": "请填写完整表单",
    "请输入邮箱": "请输入邮箱",
    "请输入密码": "请输入密码",
    "邮箱格式错误": "邮箱格式错误"
}

let group = {
    "私密": "私密",
    "企业内公开": "企业内公开",
    "公开": "公开",
    "count个成员": "{count}个成员",
    "count人订阅": "{count}人订阅",
    "暂无简介": "暂无简介",
    "非公开小组，加入可查看内容": "非公开小组，加入可查看内容",
    "加入可查看更多": "加入可查看更多",
    "暂不支持查看其它组织内小组的内容": "暂不支持查看其它组织内小组的内容",

    "加入小组": "加入小组",
    "加入成功": "加入成功",
    "订阅小组": "订阅小组",

    "订阅badge": "订阅",

    "内容发现": "内容发现",
    "企业小组": "企业小组",
    "外部企业": "外部企业",
    "外部":"外部",
    "含有外部人员的小组":"含有外部人员的小组",
    "外部人员":"外部人员",
    "订阅": "订阅",
    "已订阅": "已订阅",
    "加入": "加入",
    "已加入": "已加入",

    "退出小组": "退出小组",
    "请先转让小组":"请先转让小组",
    "仅组内成员可查看": "仅组内成员可查看",
    "企业内成员可查看": "企业内成员可查看",

    "小组成员": "小组成员",
    "组长": "组长",
    "企业内成员和其他企业成员都可查看": "企业内成员和其他企业成员都可查看",

    "企业总部不可更改小组权限": "企业总部不可更改小组权限",
    "选择转让成员": "选择转让成员",
    "请选择转让成员": "请选择转让成员",
    "确认转让": "确认转让",
    "转让成功": "转让成功",
    "转让失败，该成员作为组长的小组数量已达上限。":"转让失败，该成员作为组长的小组数量已达上限。",
    "邀请成员到": "邀请成员到",
    "组织成员邀请": "组织成员邀请",
    "邮件邀请": "邮件邀请",
    "链接邀请": "链接邀请",
    "邮箱重复":"邮箱重复",
    "输入或粘贴邮箱地址":"输入或粘贴邮箱地址",
    "请输入您的密码":"请输入您的密码",
    "请设定您的密码":"请设定您的密码",
    "支持批量输入邮箱,每行输入一个":"支持批量输入邮箱,每行输入一个",
    "请输入正确的邮箱":"请输入正确的邮箱",
    "已邀请": "已邀请",
    "通过邮件快速邀请同事加入": "通过邮件快速邀请同事加入",
    "通过分享复制链接，邀请成员加入": "通过分享复制链接，邀请成员加入",

    "时间": "时间",
    "活跃用户": "活跃用户",
    "新增内容": "新增内容",
    "新增评论": "新增评论",
    "新增点赞": "新增点赞",
    "请选择": "请选择",
    "最近七天": "最近七天",
    "半个月": "半个月",
    "一个月": "一个月",
    "三个月": "三个月",
    "半年": "半年",
    "30天趋势": "30天趋势",

    "小组描述（非必填）": "小组描述（非必填）",
    "输入小组名称": "输入小组名称",
    "输入小组名":"输入小组名",
    "创建":"创建",
    "输入小组简介（非必填）":"输入小组简介（非必填）",
    "输入邮箱": "输入邮箱",

    "解散后小组被删除，此操作不可撤回，是否确认解散？": "解散后小组被删除，此操作不可撤回，是否确认解散？",
    "是否确认退出小组": "是否确认退出小组",
    "总部": "总部",

    "小组已归档": "小组已归档",
    "组长已于": "组长已于",
    "将小组归档。小组内容依然可被搜索、浏览、点赞或评论，但无法加入和添加成员": "将小组归档。小组内容依然可被搜索、浏览、点赞或评论，但无法加入和添加成员",
    "归档后可在个人页面我的组织里查看已经归档的小组，小组将不可再见。但小组内容依然可被搜索、浏览、点赞或评论。无法加入和添加成员。":"归档后可在个人页面我的组织里查看已经归档的小组，小组将不可再见。但小组内容依然可被搜索、浏览、点赞或评论。无法加入和添加成员。",
    "选择小组类型": "选择小组类型",
    "创建讨论组": "创建讨论组",
    "创建订阅组": "创建订阅组",
    "请上传小组头像": "请上传小组头像",
    "请输入小组名称": "请输入小组名称",
    "小组名称不能超过12个字符": "小组名称不能超过12个字符",
    "小组头像": "小组头像",

    "转发": "转发",
    "转发到：": "转发到：",
    "不能转发至同一小组":"不能转发至同一小组",
    "输入你的看法": "输入你的看法",
    "我的小组":"我的小组",
    "组织室":"组织室",
    "项目室":"项目室",
    "建立群组":"建立群组",
    "圈子简介":"小组简介",
    "圈子":"小组",
    "人气圈子":'人气小组',
    "人气话题":"人气话题",
    "全部话题":"全部话题",
    "发布内容":"发布内容",
    "全部小组":"全部小组",
    "投稿确认":"投稿确认",
    "这里是全员参加的总部小组，请确认是否发布？":"这里是全员参加的总部小组，请确认是否发布？",
    "聊天室":"聊天室",
    "开启":"开启",
    "已开启":"已开启",
    "创建讨论组":"创建讨论组"
}

let dashboard = {
    "您有未发布内容，跳转其他小组将不会保留，确定跳转？": "您有未发布内容，跳转其他小组将不会保留，确定跳转？",
    "我的小组": "我的小组",
    "推荐加入": "推荐加入",
    "小组数据": "小组数据",
    "统计": "统计",
    "总用户数": "总用户数",
    "累计内容": "累计内容",
    "累计阅读": "累计阅读",
    "历史趋势": "历史趋势",
    "新增文章": "新增文章",
    "新增评论": "新增评论",
    "新增阅读": "新增阅读",
    "新增用户": "新增用户",
   
    "您的试用版本还有{day}天即将到期，":"您的试用版本还有{day}天即将到期，",
    "请提前购买。":"请提前购买。",
    "如有问题请联系开发人员。":"如有问题请联系开发人员。",

    
    "小组数据": "小组数据",
    
    "Badge排名": "Badge排名",

    "讨论组": "讨论组",
    "订阅组": "订阅组",
    "小组成员讨论空间，可用于信息交流、学习共享": "小组成员讨论空间，可用于信息交流、学习共享",
    "企业专属信息发布空间，可用于拓展品牌影响": "企业专属信息发布空间，可用于拓展品牌影响",
    "小组名": "小组名",
    "开放权限": "开放权限",
    "设置": "设置",
    "更改": "更改",
    "转让小组": "转让小组",
    "转让": "转让",
    "企业总部暂不支持转让功能": "企业总部暂不支持转让功能",
    "解散小组": "解散小组",
    "企业总部暂不支持解散功能": "企业总部暂不支持解散功能",
    "修改小组信息": "修改小组信息",
    "邀请成功": "邀请成功",
    "邀请失败": "邀请失败",
    "已经加入组织":"已经加入组织",
    "邀请链接已复制": "邀请链接已复制",

    "采集": "采集",
    "已采集": "已采集",
    "采集板": "采集板",
    "count人采集": "{count}人采集",
    "采集成功，可到企业HQ小组的采集板查看": "采集成功，可到企业HQ小组的采集板查看",
    '订阅流':'订阅流'
}
let keywords = {
   "关键词":"关键词",
   "今天的文章数":"今天的文章数",
   "文章总数":"文章总数",
   "企业内人气内容":"企业内人气内容",
   "人气News":"人气News",
   "确定删除关键字？":"确定删除关键字？",
   "添加关键词":"添加关键词",
   "订制小组成员感兴趣的主题，添加主题关键字，追踪相关情报资讯。":"订制小组成员感兴趣的主题，添加主题关键字，追踪相关情报资讯。",
   "去添加":"去添加",
   "How to use":"How to use",
   "关键词.添加":"添加"
}
let article = {
    "赞": "赞",
    "已点赞": "已点赞",
    "评论": "评论",

    "浏览": "浏览",
    "次": "次",
    "浏览count次": "浏览 {count} 次",
    "请输入你的想法":"请输入你的想法",
    "编辑": "编辑",
    "编辑文章": "编辑文章",
    "收藏": "收藏",
    "设为素材":"设为素材",
    "将该文章设为素材，方便使用白板时以素材的方式进行使用":"将该文章设为素材，方便使用白板时以素材的方式进行使用",
    "输入标签，方便寻找素材时使用":"输入标签，方便寻找素材时使用",
    "确认采集":"确认采集",
    "内容已进入素材库":"内容已进入素材库",
    "取消收藏": "取消收藏",
    "置顶": "置顶",
    "取消置顶": "取消置顶",
    "举报": "举报",

    "收藏成功": "收藏成功",
    "已取消收藏": "已取消收藏",

    "分享链接复制成功": "分享链接复制成功",

    "写下你的评论...": "写下你的评论...",

    "您不是这则信息发布者，确认删除？": "您不是这则信息发布者，确认删除？",
    
    "分享": "分享",
    "文章不存在，可能已被删除": "文章不存在，可能已被删除",
    "无访问权限": "无访问权限",
    "非公开小组，内容不可见": "非公开小组，内容不可见",
    "请先去登录": "请先去登录",
    "不能为空": "不能为空",
    "暂无评论": "暂无评论",
    "展开count条回复": "{count}条回复",
    "收起回复": "收起回复",

    "选择举报理由": "选择举报理由",
    "举报详细说明（必填）": "举报详细说明（必填）",
    "恶意灌水，垃圾广告": "恶意灌水，垃圾广告",
    "侵权内容，骚扰用户": "侵权内容，骚扰用户",
    "色情、政治等敏感消息": "色情、政治等敏感消息",
    "举报已收到，我们会进行审核处理": "举报已收到，我们会进行审核处理",

    "查看所有count条评论": "查看所有{count}条评论",

    "重新编辑": "重新编辑",
    "只有链接？加些描述吧": "只有链接？加些描述吧",
    "宽":"宽",
    "高":"高",
    "上传照片": "上传照片",
    "上传文件": "上传文件",
    "写文章": "写文章",
    "一起写": "一起写",
    "话题": "话题",
    "#插入话题#": "#插入话题#",
    "图片": "图片",
    "文件校验中...":"文件校验中...",
    "已存在链接，不能添加附件": "已存在链接，不能添加附件",
    "只准添加同一类型附件": "只准添加同一类型附件",
    "已上传附件，链接将不会自动解析": "已上传附件，链接将不会自动解析",
    "不支持添加文件夹": "不支持添加文件夹",

    "输入文字或网址...": "输入文字或网址...",
    "释放上传": "释放上传",

    "取消编辑": "取消编辑",
    "您正在编辑内容，关闭将不会保留，确定关闭？": "您正在编辑内容，关闭将不会保留，确定关闭？",

    "每次发布只能上传一个文件": "每次发布只能上传一个文件",
    "最大100MB": "最大100MB",
    "最多上传count张图片": "最多上传{count}张图片",
    "上传图片大小不能超过5MB": "上传图片大小不能超过5MB",
    "count条评论":"{count}条评论",
    "发布了一篇文章":"发布了一篇文章",
    "您不是这篇文章的作者，无法删除这篇文章":"您不是这篇文章的作者，无法删除这篇文章",
    "至少需要上传count张图":"至少需要上传{count}张图"
}

let profile = {
   "个人资料已更新":"个人资料已更新",
    "头像": "头像",
    "修改头像": "修改头像",
    "昵称": "昵称",
    "保存更改": "保存更改",
    "上传头像大小不能超过5MB": "上传头像大小不能超过5MB",
    "上传中": "上传中",
    "头像修改成功": "头像修改成功",
    "昵称不能超过20个字符": "昵称不能超过20个字符",
    "昵称修改成功": "昵称修改成功",

    "修改密码": "修改密码",
    "邮箱": "邮箱",
    "密码": "密码",
    "旧密码": "旧密码",
    "请输入旧密码": "请输入旧密码",
    "两次密码不能一致": "两次密码不能一致",
    "密码修改成功": "密码修改成功",
    "我评论的": "我评论的",
    "编辑封面":"编辑封面",
    "我创建的小组":"我创建的小组",
    "他创建的小组":"Ta创建的小组",
    "参加的圈子":"参加的圈子"
}

let organization = {
    "付费及订单": "付费及订单",
    "组织成员": "组织成员",
    "归档小组": "归档小组",
    "归档": "归档",
    "组织管理员": "组织管理员",
    "管理员": "管理员",
    "成员": "成员",
    "已停用": "已停用",
    "邀请成员到组织": "邀请成员到组织",
    "通过链接快速邀请成员加入": "通过链接快速邀请成员加入",
    "已付费名额已满，请前往续费": "已付费名额已满，请前往续费",
    "操作失败": "操作失败",
    "我已了解": "我已了解",
    "升级企业版": "升级企业版",
    "企业免费版": "企业免费版",
    "企业版": "企业版",
    "成员数量": "成员数量",
    "有效期至": "有效期至",
    "当前版本": "当前版本",
    "退出企业":"退出企业"
}

let editor = {
    "草稿已保存": "草稿已保存",
    "正在保存...": "正在保存...",
    "正在连接...": "正在连接...",
    "连接异常": "连接异常",
    "草稿箱": "草稿箱",

    "粗体": "粗体",
    "斜体": "斜体",
    "下划线": "下划线",
    "中划线": "中划线",
    "标题1": "标题1",
    "标题2": "标题2",
    "标题3": "标题3",
    "有序列表": "有序列表",
    "无序列表": "无序列表",
    "增加缩进": "增加缩进",
    "减少缩进": "减少缩进",
    "对齐方式": "对齐方式",
    "字体颜色": "字体颜色",
    "背景颜色": "背景颜色",
    "引用": "引用",
    "代码块": "代码块",
    "超链接": "超链接",
    "插入图片": "插入图片",

    "请输入标题": "请输入标题",
    "确认文章信息": "确认文章信息",
    "发布小组": "发布小组",
    "文章标题": "文章标题",
    "[文章]": "[文章]",
    "选择小组：": "选择小组：",
    "确认发布": "确认发布",
    "选择小组": "选择小组",
    "我的草稿": "我的草稿",
    "新建": "新建",
    "无标题": "无标题",
    "最后修改：": "最后修改：",

    "修改标题失败，请稍后再试...": "修改标题失败，请稍后再试...",
    "确认要删除该草稿吗？": "确认要删除该草稿吗？",
    "删除提示": "删除提示",
    "草稿删除失败，请稍后再试...": "草稿删除失败，请稍后再试...",
    "文章标题不能为空": "文章标题不能为空",
    "文章内容不能为空": "文章内容不能为空",
    "发布文章失败，请稍后再试...": "发布文章失败，请稍后再试...",
    "内容保护":"内容保护",
    "商业秘密":"商业秘密",
    "机密信息":"机密信息",
    "请输入文章":"请输入文章"
}

let interfaceErrorText = {
    "未知错误": "未知错误",
    "服务端异常": "服务端异常",
    "客户端错误": "客户端错误",
    "非法数据": "非法数据",
    "解析错误": "解析错误",
    "签名错误": "签名错误",
    "参数错误": "参数错误",
    "参数不能为空": "参数不能为空",
    "记录未找到": "记录未找到",
    "请先注册": "请先注册",
    "密码错误": "密码错误",
    "用户不存在": "用户不存在",
    "邮箱类型不正确": "邮箱类型不正确",
    "权限不足": "权限不足",
    "成员已存在": "成员已存在",
    "邮件发送成功": "邮件发送成功",
    "邀请已发送，请勿频繁操作!": "邀请已发送，请勿频繁操作!",
    "该用户已注册": "该用户已注册",
    "成员不存在": "成员不存在",
    "创建小组超过上限": "创建小组超过上限",
    "请验证邮箱": "请验证邮箱",
    "该手机号已绑定": "该手机号已绑定",
    "该用户为活跃用户": "该用户为活跃用户",

    "账户已被冻结": "账户已被冻结",
    "您已举报过此信息": "您已举报过此信息",
    "仅管理员可发布!": "仅管理员可发布!",
    "请先登录!": "请先登录!",
    "访问过于频繁": "访问过于频繁",
    "发送信息失败，请稍后重试": "发送信息失败，请稍后重试",
    "已超过有效期!": "已超过有效期!",
    "小组不存在": "小组不存在",
    "小组已存在": "小组已存在",
    "该小组仅能转让给企业用户": "该小组仅能转让给企业用户",
    "请先加入小组": "请先加入小组"
}

let product = {
    "Miks是领先的“区块链+”企业创新协作平台，基于独创的DTCP协议为企业解决优质信息共享、筛选与沉淀等问题。通过闪电DNA技术、多服务器架构，在企业内让信息架构扁平化。": "Miks是领先的“区块链+”企业创新协作平台，基于独创的DTCP协议为企业解决优质信息共享、筛选与沉淀等问题。通过闪电DNA技术、多服务器架构，在企业内让信息架构扁平化。",

    "知识圈层": "知识圈层",
    "小组作为信息的组织单位，方便浏览与管理，轻松创建团队的知识库。": "小组作为信息的组织单位，方便浏览与管理，轻松创建团队的知识库。",
    "火眼推送": "火眼推送",
    "行业热点全连接，精选全网最专业信息源，实时认知升级。": "行业热点全连接，精选全网最专业信息源，实时认知升级。",
    "闪电DNA": "闪电DNA",
    "区块链专利技术保障企业数据安全，企业知识永久上链不丢失。": "区块链专利技术保障企业数据安全，企业知识永久上链不丢失。",

    "优质信息协同筛选，挖掘企业集体智慧": "优质信息协同筛选，挖掘企业集体智慧",
    "挖掘企业隐藏知识，制造更多智慧涌现，全局呈现组织内部的知识全景。": "挖掘企业隐藏知识，制造更多智慧涌现，全局呈现组织内部的知识全景。",

    "知识跨组织学习，注入“天生创新”的组织基因": "知识跨组织学习，注入“天生创新”的组织基因",
    "走出团队“智慧茧房”，前沿干货下沉至圈层，融入时代智慧浪潮。": "走出团队“智慧茧房”，前沿干货下沉至圈层，融入时代智慧浪潮。",

    "多端协作，高效分享，不错过任何一个优质信息": "多端协作，高效分享，不错过任何一个优质信息",
    "改善企业内部知识流动不畅": "改善企业内部知识流动不畅",
    "有效保存灵感、创意，沉淀为企业知识": "有效保存灵感、创意，沉淀为企业知识",
    "实现企业个体进化成长": "实现企业个体进化成长",

    "合作案例": "合作案例",
    "在Miks上聚合全球顶级商业资讯，深度报道协作采编、设计一站式服务，为全球服务器输出定制化的行业知识。": "在Miks上聚合全球顶级商业资讯，深度报道协作采编、设计一站式服务，为全球服务器输出定制化的行业知识。",
    "集团创新部门与数千家供应商在Miks上通力协作，为IOT及智慧城市业务的创新激发灵感。": "集团创新部门与数千家供应商在Miks上通力协作，为IOT及智慧城市业务的创新激发灵感。",
    "通过Miks打通组织内部信息屏障，使得团队内部认知代沟的同步速度翻倍，在高速奔跑的行业认知赛道上让每个成员都能成长。": "通过Miks打通组织内部信息屏障，使得团队内部认知代沟的同步速度翻倍，在高速奔跑的行业认知赛道上让每个成员都能成长。",
    "将社区生态架构于Miks之上，为数万社区用户提供多样化的信息服务，社区IP影响力的触达率提升70%。": "将社区生态架构于Miks之上，为数万社区用户提供多样化的信息服务，社区IP影响力的触达率提升70%。",

    "节点计划": "节点计划",
    "Miks支持节点搭建，任何组织和个人抵押数字资产即可成为节点生态一员": "Miks支持节点搭建，任何组织和个人抵押数字资产即可成为节点生态一员",
    "可选底层设施": "可选底层设施"
}

let price = {
    "选择适合你的版本": "选择适合你的版本",
    "Miks是一个分布式知识共享协作平台，基于独创的DTCP协议为企业解决优质信息共享、筛选与沉淀等问题。通过闪电DNA技术、多服务器架构，在企业内让信息架构扁平化。": "Miks是一个分布式知识共享协作平台，基于独创的DTCP协议为企业解决优质信息共享、筛选与沉淀等问题。通过闪电DNA技术、多服务器架构，在企业内让信息架构扁平化。",

    "创新版": "创新版",
    "基础版": "基础版",
    "适用于10人以下团队": "适用于10人以下团队",
    "可创建3个私密讨论组": "可创建3个私密讨论组",
    "每小组最多可加入10人": "每小组最多可加入10人",
    "2GB专属云空间": "2GB专属云空间",
    "不限加入/订阅其他小组": "不限加入/订阅其他小组",
    "信息区块链永久存证": "信息区块链永久存证",

    "企业/组织/团队版": "企业/组织/团队版",
    "适用于10人以上团队": "适用于10人以上团队",
    "立即升级": "立即升级",
    "基础版本所有功能": "基础版本所有功能",
    "新增团队总部小组": "新增团队总部小组",
    "可创建10个讨论组": "可创建10个讨论组",
    "每小组最多可加入500人": "每小组最多可加入500人",
    "可创建2个服务器公开订阅组": "可创建2个服务器公开订阅组",
    "200GB专属云空间": "200GB专属云空间",

    "试用到期": "试用到期",
    "试用期已到，可登录Miks网页端进行续费，你发布的所有内容都不受影响": "试用期已到，可登录Miks网页端进行续费，你发布的所有内容都不受影响",
    "直接升级": "直接升级",
    "了解详情": "了解详情"
}
let blankboard = {
   "未命名" : "未命名",
   "白板"   :"白板",
   "推荐素材" : "推荐素材",
   "全部"   :"全部",
   "文章"   :"文章",
   "短文"   :"短文",
   "图片"   :"图片",
   "我的素材"  : "我的素材",
   "移除"   :"移除",
   "编辑"   :"编辑",
   "blankboard.暂无数据":"暂无数据",
   "取消选择":"取消选择",
   "请勿重复采集!":"请勿重复采集!",
   "评论顺":"评论",
   "浏览顺":"浏览",
   "badge":"badge",
   "该白板只限单人编辑，该操作会向正在编辑者发出申请，需要同意后才能进行编辑":"该白板只限单人编辑，该操作会向正在编辑者发出申请，需要同意后才能进行编辑",
   "发出":"发出",
   "编辑中":"编辑中",
   "开始编辑":"开始编辑",
   "正在编辑":"正在编辑",
   "申请编辑":"申请编辑",
   "申请编辑白板":"申请编辑白板",
   "拒绝":"拒绝",
   "同意":"同意",
   "置顶层":"置顶层",
   "置底层":"置底层",
   "在空白处双击添加便利贴":"在空白处双击添加便利贴",
   "暂无人编辑":"暂无人编辑",
   '确认是否删除白板"name"?':'确认是否删除白板"{name}"?',
   '下载':'下载',
   '开始日期':'开始日期',
   '结束日期':'结束日期',
   '复制':'复制',
   '确认是否删除"name"?':'确认是否删除"{name}"?',
   '素材库':'素材库',
   '输入搜素的关键词':'输入搜素的关键词',
   '日期':'日期',
   '是否退出白板':'是否退出白板',
   '未处于编辑状态，请点击开始编辑':'未处于编辑状态，请点击开始编辑'
}

export default {
    ...common,
    ...header,
    ...guideTips,
    ...search,
    ...notification,
    ...loginRegister,
    ...formVerify,
    ...group,
    ...dashboard,
    ...article,
    ...profile,
    ...organization,
    ...editor,
    ...interfaceErrorText,
    ...product,
    ...price,
    ...blankboard,
    ...keywords,
    "xxx": "xxx",  //to be translated
}