const resourcePath='@/api/v3/blankboard.js';import {httpClient} from './v3';

const BlankBoardAPI = {
    createBoard: (data) => {
        return httpClient.post('/board', data);
    },
    updateBoard:(boardId,data) =>{
      return httpClient.put('/board/' + boardId, data);
    },
    getBoard:(boardId) => {
      return httpClient.get('/board/' + boardId);
    },
    getBoardLst:(data) =>{
      return httpClient.get('/board',{params:data});
    },
    applyForEdit:(boardId)=>{
      return httpClient.post('/board/' + boardId + '/editor');
    },
    responseForApplication:(boardId,data)=>{
      return httpClient.put('/board/' + boardId + '/editor',data);
    },
    deleteBoard:(boardId) =>{
       return httpClient.delete(`/board/${boardId}`)
    }
}

export default BlankBoardAPI;