const resourcePath='@/common/consts.js';
export default {
   GROUP_PUBLIC_TYPE_PRIVATE : 1,
   GROUP_PUBLIC_TYPE_PUB_IN_ENTPRS:3,
   GROUP_PUBLIC_TYPE_PUB_TO_ALL:4,

   GROUP_TYPE_DISCUSS:1,
   GROUP_TYPE_SUBSCRIBE:9,

   ARTICLE_TYPE_SHORT:0,
   ARTICLE_TYPE_RICH_TEXT:1,
   ARTICLE_TYPE_AWK_EYE:3,
   ARTICLE_TYPE_SUPP:2,
   ARTICLE_TYPE_SUB:4,


   CONTENT_TYPE_LONG_ARTICLE:1,
   CONTENT_TYPE_MIKS_SHORT:2,
   CONTENT_TYPE_CHROME_EXT:3,
   CONTENT_TYPE_MARKDOWN:4,
   CONTENT_TYPE_COLLAB:5
}