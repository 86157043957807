const resourcePath='@/api/v3/draft.js';import {httpClient} from "./v3";

let DraftAPI = {
    WebSocketSyncContent: (draftId) => {

        let endpoint = httpClient.defaults.baseURL + "/drafts/" + draftId + "/ws";

        if(!/^(https|http)/.test(endpoint)) {
            // relative path
            let hostname = window.location.href.split("/");

            endpoint = hostname[0] + "//" + hostname[2] + endpoint;
        }

        endpoint = endpoint.replace(/^http/, "ws");

        let sessionKey = localStorage.getItem("session_key");

        if(sessionKey) {
            endpoint = endpoint + "?session_key=" +sessionKey;
        }

        return endpoint;
    },
    GetDrafts: () => {
        return httpClient.get("/drafts");
    },
    GetDraft: (draftId) => {
        return httpClient.get("/drafts/" + draftId);
    },
    CreateDraft: (articleId) => {

        let data = {};

        if(articleId) {
            data.article_id = articleId;
        }

        return httpClient.post("/drafts", data);
    },
    UpdateDraft: (draftId, data) => {
        return httpClient.put("/drafts/" + draftId, data);
    },
    DeleteDraft: (draftId) => {
        return httpClient.delete("/drafts/" + draftId);
    },
    PublishDraft: (draftId, groupId, htmlContent) => {

        let data = {
            group_id: groupId,
            content: htmlContent
        };

        return httpClient.post("/drafts/" + draftId + "/publish", data);
    }
};

export default DraftAPI;
