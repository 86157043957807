const resourcePath='@/api/organization.js';import config from '@/config';

const common_url = config.server_version + '/organizations';

const staff = '/v2/user';

export default {
    common_url,
    invite(oid) {
        return `${common_url}/${oid}/invite`
    },
    join(oid) {
        return `${common_url}/${oid}/member`
    },
    getOrgInfo(oid) {
        return `${common_url}/${oid}`
    },
    staff
}
