const resourcePath='@/common/function/txt-filter.js';export default (text) => {
    return text
        //html()方法将 & 转成了 &amp; 需要转回来（以下都需要转回来）
        .replace(/&amp;/ig, '&')
        .replace(/&lt;/ig, '<')
        .replace(/&gt;/ig, '>')
        .replace(/&nbsp;/ig, ' ')

        //br转\n safari会把换行变成br
        .replace(/<div><br><\/div>/ig, '\n')
        .replace(/<br>/ig, '\n')

        .replace(/<p>/ig, '<div>')
        .replace(/<\/p>/ig, '</div>')
        
        .replace(/<\/div><div>/ig, '\n')
        .replace(/<div>/ig, '\n')
        .replace(/<\/div>/ig, '\n')

        //防止直接粘贴进html代码
        .replace(/<span +.*?>/ig, '')
        .replace(/<\/span>/ig, '')

        //多个换行转一个    //输入时不做限制，拿取数据展示时 做优化
        // .replace(/[\n\r]{2,}/ig, '\n\n')
        // .replace(/[\r]/ig, '\n')
        // .replace(/(\r\n)/ig, '\n')
        // .replace(/(\n\r)/ig, '\n')

        // .replace(/(\r\n)/ig, '123')
        // .replace(/(\n\r)/ig, '456')
        // .replace(/(\r)/ig, '123')
        // .replace(/(\n)/ig, '456')

        //两个br是有意义的，一个换行，一个用作间隔

        //去除首尾的空格 换行
        .replace(/(^(\s|\n)+)|((\s|\n)+$)/ig, '')

        //safari 复制粘贴自动带上div标签 需要去除

        // .replace(/<\/?div>/ig, '')
        // .replace(/<div>/ig, '<br>')  //mac系统有关，非safari浏览器
        // .replace(/<div>/ig, '\n')  //mac系统有关，不止是safari浏览器
        // .replace(/<\/div>/ig, '')

        //firefox 复制粘贴会把 div、p 剔除掉，全部转成 br
        //firefox 输入会自动包裹进div， 回车表现为：<div><br></div>

        //chrome 复制粘贴会把 div、p 都转成 div 包裹， br 转成 空白的div
        // .replace

        //待优化：复制进来带 br 的纯文本，会重复添加 br，导致间隔很大，TODO：新建文档测试

        //待优化：复制进来的段落，回车后在中间插入内容，会引发div嵌套，造成换行
        //输入多行后，需要在前面插入新的一行，也会造成这种情况
        //参考百度贴吧，同存在此问题


        //待优化：只有IE使用 <p> 元素而不是 <div> 来包裹新生成的文本行
        //其他无法定位的操作也有可能会生成 p标签
        // .replace(/<p>/ig, '<div>')
        // .replace(/<\/p>/ig, '</div>')
        // .replace(/<\/?p>/ig, '')

        //待优化：var reg = /<[^>]+>/ig  可以去除所有标签吗？
}