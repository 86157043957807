const resourcePath='@/store/modules/users.js';import UserAPI from '@/api/v3/user';

const state = {
    usersInfo: {},
    usersDetail: {},
    appUser: {}
};

const getters = {

};

const actions = {
    getUserInfo(context, userId) {
        UserAPI.getUserInfoById(userId)
            .then((user) => {

            })
            .catch((err) => {

            });
    },
    setAppUser(context, appUser) {
        context.commit('setAppUser', appUser);
    }
};

const mutations = {
    setAppUser (state, user) {
        state.appUser = user;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
