const resourcePath='@/api/v3/user.js';import {httpClient} from "./v3";

let UserAPI = {
    getUserInfoById: (userId) => {
        return httpClient.get("/users/" + userId);
    },
    getUserGroupStatus:(userId) => {
      return httpClient.get("/users/" + userId + "/group");
    }
};

export default UserAPI;
