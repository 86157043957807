const resourcePath='@/api/account.js';import config from '@/config';

const common_url = config.server_version + '/account';

const user_query = config.server_version + '/user/info';

const user_groups = config.server_version + '/user/groups/info';

export default {
    common_url,
    login: common_url + '/email/login',//用户登录
    register: common_url + '/email/register',//用户注册
    user: {
        info: common_url + '/email/base/info',//用户信息
        update: common_url + '/email/base/update',//用户信息
    },
    password:{
        update: common_url + '/email/password/update',//修改密码
        activation: common_url + '/email/password/activation',//激活密码
        forgotten: common_url + '/email/password/forgotten',//忘记密码
    },
    activation: common_url + '/email/activation',//用户邮箱验证
    email_type: common_url + '/email/type',//用户邮箱验证
    bind: common_url + '/email/bind',//用户信息

    user_query,
    user_groups,

    openUserInfo(uid) {
        return `${common_url}/email/person/${uid}/info`
    },

    wxLogin: common_url + '/wx/login',
    wxBind: common_url + '/wx/bind'

}