var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.trigger
    ? _c("div", { staticClass: "v-header" }, [
        _c("div", { staticClass: "text" }, [
          _vm.locale == "jp"
            ? _c("span", [
                _vm._v(
                  "HAROが最新版にアップデートされました。万が一画面が正常に表示されない場合は、ブラウザ画面の再読み込みを行うか"
                ),
                _c(
                  "span",
                  { staticClass: "refresh", on: { click: _vm.onRefresh } },
                  [_vm._v("再度ログインをしてください。")]
                )
              ])
            : _c("span", [
                _vm._v("系统已完成版本迭代，如若无法正常使用Miks，请"),
                _c(
                  "span",
                  { staticClass: "refresh", on: { click: _vm.onRefresh } },
                  [_vm._v("刷新")]
                ),
                _vm._v("一下页面，或退出账户重新登录")
              ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }