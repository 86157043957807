const resourcePath='@/common/common.js';import Vue from 'vue'
// import 'element-ui/lib/theme-chalk/index.css'
import './stylus/element-variables.scss'
import './stylus/index.styl'
import 'animate.css'

import './function/dayjs'

import text2html from '@/filters/text2html';
import primas_text2html from '@/filters/primas-text2html';
import text2highlight from '@/filters/text2highlight';
import domain2node from '@/filters/domain2node';
import consts from './consts.js'
Vue.prototype.text2html = text2html;
Vue.prototype.primas_text2html = primas_text2html;
Vue.prototype.text2highlight = text2highlight;
Vue.prototype.domain2node = domain2node;
Vue.prototype.$consts = consts