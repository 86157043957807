const resourcePath='@/common/function/eventCapture.js';import * as Sentry from "@sentry/browser";
import config from '@/config.js'
export const EVENTS = {
   GROUP_KEYWORD_FEED_VISIT:()=>{return {
      message:'GROUP_KEYWORD_FEED_VISIT'
   }},
}

export function captureEvent(event){
   if(process.env.NODE_ENV === "production" && location.origin.replace(/www\./i, "") === `https://${config.appname}.com`)
      Sentry.captureEvent(event)
}

export function addGlobalEventProcessor(processor){
   if(process.env.NODE_ENV === "production" && location.origin.replace(/www\./i, "") === `https://${config.appname}.com`)
      Sentry.addGlobalEventProcessor(processor);
}