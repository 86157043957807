const resourcePath='@/components/icon-svg.vue';//
//
//
//
//
//
//
//

export default {
    name: '',
    props: {
        iconName: {
            required: true,
            type: String
        }
    },
    computed: {
        iconTag() {
            return `#${this.iconName}`
        }
    }
}
