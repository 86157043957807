const resourcePath='@/common/function/xssfilter.js';import xss from 'xss'
export default (html) => {
   let xhtml = ('' || html).replace(/(\n)/ig,'<br>');
   return xss(xhtml,{
      whiteList: {
         a: ['href', 'title', 'target','style','class'],
         div:['style','class'],
         p:['style','class'],
         table:['style','class'],
         tr:['style','class'],
         td:['style','class'],
         th:['style','class'],
         tbody:['style','class'],
         span:['style','class'],
         style:['type'],
         link:['rel','href'],
         thead:['style','class'],
         code:['style','class'],
         video:['src','style','class','width','height','controls'],
         audio:['src','style','class'],
         source:['src','type'],
         br:[]
       }
   })
}