const resourcePath='@/store/modules/modal_dialogs.js';const state = {
    activeDialogName:null,
    activeDialogData:{},
    registedDialogs:{
    }
};
const getters = {
};
const actions = {}
const mutations = {
    openDialog (state,payload) {
        state.activeDialogName = payload.dialogName;
        state.activeDialogData = payload.dialogData
        document.querySelector('html').style.overflow = 'hidden'
    },
    closeDialog(state,payload) {
      state.activeDialogName = null;
      state.activeDialogData = null
      document.querySelector('html').style.overflow = 'visible'
    },
    registDialogs(state,registedDialogs) {
      Object.assign(state.registedDialogs,registedDialogs)
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
