var render = function() {
  var _obj, _obj$1, _obj$2
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      class:
        ((_obj = {}),
        (_obj[_vm.css_style.explore] = !_vm.linkAppearance1),
        (_obj[_vm.css_style.link] = true),
        _obj),
      attrs: {
        href: _vm.article.link && _vm.article.link.url,
        target: "_blank"
      },
      on: {
        click: function($event) {
          $event.stopPropagation()
          return _vm.read_detail.apply(null, arguments)
        }
      }
    },
    [
      _vm.article.mark && _vm.article.mark.mark_id
        ? _c("link-mark", { attrs: { mark: _vm.article.mark } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { class: _vm.css_style["link-content"] }, [
        _c(
          "div",
          {
            class:
              ((_obj$1 = {}),
              (_obj$1[_vm.css_style["img-apperance-2"]] = !_vm.linkAppearance1),
              (_obj$1[_vm.css_style["margin"]] = _vm.$route.name === "explore"),
              (_obj$1[_vm.css_style["link-img-box"]] = true),
              _obj$1)
          },
          [
            _c("img", {
              directives: [
                {
                  name: "default-link-bg",
                  rawName: "v-default-link-bg",
                  value: {
                    src: _vm.linkAppearance1
                      ? _vm.article.link.head_image_url +
                        "?x-oss-process=image/resize,m_fill,w_50,h_42,limit_0"
                      : _vm.article.link.head_image_url +
                        "?x-oss-process=image/resize,m_fill,w_120,h_80,limit_0"
                  },
                  expression:
                    "{src:linkAppearance1 ? \n                `${article.link.head_image_url}?x-oss-process=image/resize,m_fill,w_50,h_42,limit_0` : \n                `${article.link.head_image_url}?x-oss-process=image/resize,m_fill,w_120,h_80,limit_0`}"
                }
              ],
              class: _vm.css_style["link-img"],
              attrs: {
                alt: "picture error",
                src: _vm.linkAppearance1
                  ? _vm.article.link.head_image_url +
                    "?x-oss-process=image/resize,m_fill,w_50,h_42,limit_0"
                  : _vm.article.link.head_image_url +
                    "?x-oss-process=image/resize,m_fill,w_120,h_80,limit_0"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c("div", { class: _vm.css_style["link-title"] }, [
          _c("div", {
            class:
              ((_obj$2 = {}),
              (_obj$2[_vm.css_style.apperance2] = !_vm.linkAppearance1),
              (_obj$2[_vm.css_style.title] = true),
              _obj$2),
            domProps: {
              innerHTML: _vm._s(
                _vm.text2highlight(
                  _vm.article.link.title || _vm.article.link.url,
                  _vm.article.highlight
                )
              )
            }
          }),
          _vm._v(" "),
          _vm.showSource
            ? _c("div", { class: _vm.css_style.source }, [
                _vm._v(_vm._s(_vm.linkSource))
              ])
            : _vm._e()
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }