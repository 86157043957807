const resourcePath='@/api/v4/v4.js';import config from '@/config';
import axios from 'axios';

let versionPrefix = "v4";
let baseUrl = config.base_url + "/" + versionPrefix;

let httpClient = axios.create({
    baseURL: baseUrl,
    withCredentials: true
});

httpClient.interceptors.request.use(
    (config) => {

        // Add authentication token
        let sessionKey = localStorage.getItem('session_key');

        if(sessionKey)
            config.headers["SessionKey"] = sessionKey;

        return config;
    },
    (error) => {

    }
);

httpClient.interceptors.response.use(
    (response) => {

        if(response.status === 200) {
            // Normal response
            return Promise.resolve(response.data.data);

        } else if (response.status === 400) {
            // Validation error
            return Promise.reject(response.data.message);
        } else {
            // Exceptions
            return Promise.reject("server exception");
        }
    },
    (error) => {
        return Promise.reject(error);
    });

export { httpClient };
