const resourcePath='@/directives/full-text-self.js';export default {
    inserted(el, binding) {
        let $el = $(el);
        let realH = $el.height();
        let h = binding.value.maxHeight;//最大高度
        if (realH > h) {
            $el.addClass('collapse').height(h).attr('title', '点击展开').css('cursor', 'pointer');
            let pos1, pos2;
            $el.on(`click.markcontent`, function (event) {
                    // let d = Math.sqrt((pos1.x-pos2.x)*(pos1.x-pos2.x)+(pos1.y-pos2.y)*(pos1.y-pos2.y))
                    // if(d>7) return;
                    if (!window.getSelection().isCollapsed) {
                        return
                    }
                    let $fulltext = $(this);
                    if ($fulltext.hasClass('collapse')) {
                        $el.height(realH).attr('title', '点击收起')
                        $fulltext.removeClass('collapse')
                    } else {
                        $el.height(h).attr('title', '点击展开')
                        $fulltext.addClass('collapse')
                    }
                })
        }
        // let value=el.innerText;
        // let len=140;
        // if(value.length>len){
        //     el.innerHTML+='<div class="full-text expand">展开</div>'
        // }

    }
};
