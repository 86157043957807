const resourcePath='@/api/v3/group.js';import {httpClient} from './v3';

let GroupAPI = {
    getGroups: (data) => {
        // return httpClient.get('/groups/', data)
        return httpClient.get('/groups', {params: data})
    },
    getGroup: (groupId) => {
        return httpClient.get('/groups/' + groupId)
    },
    createGroup: (data) => {
        // return httpClient.post('/groups/', data)
        // return httpClient.post('/groups/', {data})
        return httpClient.post('/groups/', {params: data})
    },
    updateGroup: (groupId, data) => {
        return httpClient.put('groups' + groupId, data)
    },
    getGroupUsers: (groupId) => {
        return httpClient.get('/groups')
    },

    joinGroup: () => {
        return httpClient.post('/groups')
    },
    exitGroup: (groupId, userId) => {
        return httpClient.delete('/groups/' + groupId + '/' + userId)
    },

    getFilesAndDocs: (groupId) => {
        return httpClient.get('/dashboard/' + groupId)
    }
};

export default GroupAPI;

