const resourcePath='@/axios.config.js';import Vue from 'vue'
import axios from 'axios'
import * as util from '@/common/function/util'
import config from '@/config'

axios.defaults.baseURL = config.base_url;
axios.defaults.withCredentials = true;

//火眼需要Authorization
let auth='iyuanben eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1OTM2ODk5NTYsImlkIjoiYWRtaW4iLCJvcmlnX2lhdCI6MTU2MjE1Mzk1Nn0.wxO313fceaDaCxn9-wyRb9cnrPR1r6CNrip1hnobtAw'
axios.defaults.headers.common['Authorization'] = auth;
//添加响应拦截器
axios.interceptors.response.use(function(response){
    //对响应数据做些事
    return response
},util.catchError);

Object.defineProperty(Vue.prototype, '$axios', { value: axios });
export default axios