const resourcePath='@/api/v3/v3.js';import config from '@/config';
import axios from 'axios';
import {catchError} from '@/common/function/util.js'
let versionPrefix = "v3";
let baseUrl = config.base_url + "/" + versionPrefix;

let httpClient = axios.create({
    baseURL: baseUrl,
    withCredentials: true
});

httpClient.interceptors.request.use(
    (config) => {

        // Add authentication token
        let sessionKey = localStorage.getItem('session_key');
        config.headers["SessionKey"] = sessionKey;

        return config;
    },
    (error) => {

    }
);

httpClient.interceptors.response.use(
    (response) => {
        // Parse wrapper status data
        // Handle common errors

        if(response.status !== 200) {

            // Not OK response
            // Should be handled by error catcher

            return response;
        }
        // Handle our own error messages
        let data = response.data;

        if(data.result_code === 600) {
            return data.data;
        } else {
            return parseErrorResponse(data);
        }
    },
    (error) => {
        if(error.response && error.response.data) {
            return parseErrorResponse(error,error.response.data);
        } else if(error.response && error.config.defaultCatch) {
            return catchError(error)
        }
        return Promise.reject(error);
    });

let parseErrorResponse = (error,data) => {
    if(data.result_code && error && error.config.defaultCatch) {
      catchError(error)
    } else {
      if(data.error_message && data.error_message.zh) {
         return Promise.reject(data.error_message.zh);
      } else if(data.error_message && typeof(data.error_message) === 'string') {
         return Promise.reject(data.error_message);
      } else {
         return Promise.reject(data);
      }
   }
};

export { httpClient };