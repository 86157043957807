const resourcePath='@/store/modules/blankboard.js';import MaterialAPI from '@/api/v3/material';
import BlankBoardAPI from '@/api/v3/blankboard';
import {dto2Material} from '@/common/function/blankboard/blockregistry';
const state = {
   sidebar:{
      selectedId : null,
      dragId:null,
      materials:{
         Recommend:[],
         RecommendTotal:0,
         Mine:[],
         MineTotal:0,
      }
   },
   board:{
      boards:[],
      currentBoard:null,
      boardMoving:false,
      boardMode:'NONE',
      editable:false,
      editingBlockId:null
   },
   isDragging:false,
   isSaving:false,
   isItemResizing:false,
   isItemEditing:false
};

const getters = {
   
};

const actions = {
   setSideBarSelected(context, selectedId) {
      context.commit('setSideBarSelected',selectedId);
   },
   // setSideBarDragComponent(context,selectedId) {
   //    context.commit('setSideBarDragComponent',selectedId);
   // },
   async fetchMaterials(context,payload){
      let result = await MaterialAPI.getMaterials(payload),
          material_count = result.material_count;
      let materialGrp = payload.materialGrp,
          updatedRes = result.material_list.reduce((pre,cur)=>{
            let converted =  dto2Material(cur,materialGrp)
            if(converted)
               pre.push(converted)
            return pre;
         },[]);
      context.commit(`append${materialGrp}Materials`,updatedRes);
      return {updatedRes,material_count};
   },
   async createNewBlankBoard(context,payload) {
      let result = await BlankBoardAPI.createBoard(payload),
          resData = result.data.data;
      return resData;
   },
   async saveBlankBoard(context,payload) {
      context.commit('setSaving',true)
      let result = await BlankBoardAPI.updateBoard(payload.board_id,payload)
      context.commit('setSaving',false)
      return result;
   },
   async loadBlankBoard(context,board_id) {
      let result = await BlankBoardAPI.getBoard(payload)
      return result;
   },
   async listBlankBoard(context,payload) {
      let result = await BlankBoardAPI.getBoardLst(payload)
      context.commit('updateBoards',result.board_list)
      return result;
   }
};

const mutations = {
   setSideBarSelected (state, selectedId) {
      state.sidebar.selectedId = selectedId;
   },
   setBoardEditable(state,value) {
      state.board.editable = value;
   },
   setBoardItemResizing(state,value) {
      state.isItemResizing = value;
   },
   setBoardItemEditing(state,value) {
      state.isItemEditing = value;
   },
   setBoardMoving(state, value) {
      state.board.boardMoving = value;
   },
   setBoardMode(state,value){
      state.board.boardMode = value;
   },
   initMaterials(state){
      state.sidebar.materials = {Recommend:[],Mine:[],RecommendTotal:0,MineTotal:0};
   },
   setEditingBlockId(state,payload){
      state.board.editingBlockId = payload
   },
   clearMaterials(state,materialGrp) {
      state.sidebar.materials[materialGrp] = [];
      state.sidebar.materials[materialGrp + 'Total'] = 0;
   },
   appendRecommendMaterials(state,materials) {
      let id_Map = new Map();
      state.sidebar.materials.Recommend.forEach((itm)=>{
         id_Map.set(itm.uniqueId,1)
      })
      let temp = [];
      materials.forEach((itm)=>{
         if(!id_Map.has(itm.uniqueId))
            temp.push(itm)
      })
      state.sidebar.materials.Recommend = state.sidebar.materials.Recommend.concat(temp);
      state.sidebar.materials.RecommendTotal += materials.length
   },
   appendMineMaterials(state,materials) {
      let id_Map = new Map();
      state.sidebar.materials.Mine.forEach((itm)=>{
         id_Map.set(itm.uniqueId,1)
      })
      let temp = []
      materials.forEach((itm)=>{
         if(!id_Map.has(itm.uniqueId))
            temp.push(itm)
      })
      state.sidebar.materials.Mine = state.sidebar.materials.Mine.concat(temp);
      state.sidebar.materials.MineTotal += materials.length
   },
   updateBoards(state,boards) {
      state.board.boards = boards;
   },
   setCurrentBoard(state,board) {
      state.board.currentBoard = board;
   },
   setSaving(state,value) {
      state.isSaving = value
   }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
